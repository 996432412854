import { Box, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import CustomButton from "shared/components/customButton";
import Loader from "shared/components/loader/animation";
import PaginationComponent from "shared/components/pagination";
import { toastMessage } from "shared/components/toast";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import Confirmation from "shared/modals/confirmation";
import AddPlan from "shared/modals/planForm";
import TopUpBalance from "shared/modals/topUpBalance";
import { routeConstant } from "shared/routes/routesConstant";
import {
  activatePlanService,
  deleteBalanceService,
  deletePlanService,
  getAllBalanceService,
  getAllPlansService,
  inActivatePlanService,
} from "shared/services/productManagerService";
import BalanceTable from "./balanceTable";
import { planCategories, plan_category, plan_types_category } from "./constant";
import PlansTable from "./plansTable";
import styles from "./style.module.scss";
import classNames from "classnames";
import ManagePlan from "shared/modals/managePlans";

function Membership() {
  const [openAdd, setOpenAdd] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<any>(plan_category[0].value);
  const [activePlanTypeTab, setActivePlanTypeTab] = useState<any>(
    plan_types_category[0].value
  );
  const [allPlans, setAllPlans] = useState<any>(null);
  const [allBalance, setAllBalance] = useState<any>(null);
  const [selectedPlan, setSelectedPlan] = useState<any>(null);
  const [openConfirm, setOpenConfirmation] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [action, setAction] = useState<any>({
    label: "",
    type: "",
  });
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [dataLoader, setDataLoader] = useState<boolean>(false);
  const [selectedBalance, setSelectedBalance] = useState<any>(null);
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [openBalance, setOpenBalance] = useState<boolean>(false);
  const [viewOnly, setViewOnly] = useState<boolean>(false);
  const [editOnly, setEditOnly] = useState<boolean>(false);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [activePage, setActivePage] = useState<number>(1);

  const [isSchoolTabSelected, setIsSchoolTabSelected] =
    useState<boolean>(false);

  const handleCloseForm = () => {
    setOpenForm(false);
    setSelectedPlan(null);
    setEditOnly(false);
    setViewOnly(false);
  };

  const handleOpenForm = (item?: any) => {
    setOpenForm(true);
    if (item) {
      setViewOnly(true);
      setSelectedPlan(item);
    }
    setViewOnly(false);
  };

  const handleCloseBalance = () => {
    setOpenBalance(false);
    setSelectedPlan(null);
  };

  const handleOpenBalance = (item?: any) => {
    setOpenBalance(true);
    if (item) {
      setSelectedBalance(item);
    }
  };

  const getAllPlans = () => {
    setDataLoader(true);
    getAllPlansService({ category: activeTab }, activePage, activePlanTypeTab)
      .then(({ data }) => {
        setAllPlans(data?.data?.data);
        setDataLoader(false);
        setTotalPage(data?.data?.last_page);
      })
      .catch((err) => {
        console.log("err", err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
        setDataLoader(false);
      });
  };

  const getAllBalance = () => {
    setDataLoader(true);
    getAllBalanceService()
      .then(({ data }) => {
        setAllBalance(data?.data);
        setDataLoader(false);
      })
      .catch((err) => {
        console.log("err", err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
        setDataLoader(false);
      });
  };

  useEffect(() => {
    if (activeTab === planCategories.balance) {
      getAllBalance();
    } else {
      getAllPlans();
    }
    //eslint-disable-next-line
  }, [activeTab, activePage, activePlanTypeTab]);

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
    setAction({
      label: "",
      type: "",
    });
    setSelectedItem(null);
  };

  const handleOpenConfirmation = (label: String, type: String, item: any) => {
    setOpenConfirmation(true);
    setAction({
      label: label,
      type: type,
    });
    setSelectedItem(item);
  };
  const handleActions = (action: any) => {
    setLoader(true);
    let service =
      action.type === "delete" && activeTab - 2 !== 2
        ? deletePlanService
        : action.type === "delete" && activeTab - 2 === 2
        ? deleteBalanceService
        : action.type === "active"
        ? activatePlanService
        : action.type === "inactive"
        ? inActivatePlanService
        : null;
    service?.(selectedItem?.id)
      .then(({ data }) => {
        toastMessage("success", data?.message);
        getAllPlans();
        getAllBalance();
        setLoader(false);
        handleCloseConfirmation();
      })
      .catch((err) => {
        setLoader(false);
        toastMessage("error", err?.response?.data?.message);
      });
  };

  useBreadcrumbs({
    title: "Membership",
    path: routeConstant.membership.path,
  });

  return (
    <div className={styles.mainContainer}>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={activeTab - 1}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            TabIndicatorProps={{
              style: {
                backgroundColor: "#9A469B",
                color: "red !important",
              },
            }}
            sx={{
              "& .MuiTab-root.Mui-selected": {
                color: "#9A469B",
              },
            }}
          >
            {plan_category?.map((item) => {
              return (
                <Tab
                  label={item?.label}
                  className={styles.tabs}
                  onClick={() => {
                    setSelectedItem(null);
                    setSelectedPlan(null);
                    setActiveTab(item?.value);
                    setSelectedBalance(null);
                    setActivePage(1);
                  }}
                />
              );
            })}
          </Tabs>
        </Box>
      </Box>

      <div className={styles.outerMostContainer}>
        <Box className={styles.topHeadContainer}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <div className={styles.tableHeading}>
              {activeTab === planCategories.balance
                ? "Balance records"
                : `Plans history for ${
                    activeTab === 1
                      ? "individual account"
                      : activeTab === 2
                      ? "family account"
                      : activeTab === 3
                      ? "school"
                      : ""
                  }`}
            </div>
            <div className={styles.tableSubHeading}>
              {activeTab === planCategories.balance
                ? "Manage your balance records from here."
                : "Activated and deactivated plans list showing below."}
            </div>
          </Box>
          <Box className={styles.filterContainer}>
            <div className={styles.gridContainer}>
              <CustomButton
                text={"Add New"}
                buttonStyle={styles.addBtn}
                handleClick={() => {
                  if (activeTab === planCategories.balance) {
                    handleOpenBalance();
                  } else {
                    setOpenAdd(true);
                  }
                }}
              />
            </div>
          </Box>
        </Box>

        {activeTab !== planCategories.balance &&
          activeTab !== planCategories.school && (
            <Box>
              <Tabs
                value={activePlanTypeTab - 1}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#EF437B",
                    color: "red !important",
                  },
                }}
                sx={{
                  "& .MuiTab-root.Mui-selected": {
                    color: "#EF437B",
                    background: "#EF437B1A",
                  },
                }}
              >
                {plan_types_category?.map((item) => {
                  return (
                    <Tab
                      label={item?.label}
                      className={
                        activePlanTypeTab === item?.value
                          ? classNames(
                              styles.plansCategoryTabs,
                              styles.activeLabel
                            )
                          : styles.plansCategoryTabs
                      }
                      onClick={() => {
                        setActivePlanTypeTab(item?.value);
                      }}
                    />
                  );
                })}
              </Tabs>
            </Box>
          )}

        {activeTab !== planCategories.balance ? (
          <PlansTable
            activeTab={activeTab}
            setIsSchoolTabSelected={setIsSchoolTabSelected}
            handleOpenForm={handleOpenForm}
            dataLoader={dataLoader}
            allPlans={allPlans}
            setViewOnly={setViewOnly}
            setEditOnly={setEditOnly}
            handleOpenConfirmation={handleOpenConfirmation}
            setOpenAdd={setOpenAdd}
            setSelectedItem={setSelectedItem}
            setSelectedPlan={setSelectedPlan}
          />
        ) : (
          <BalanceTable
            activeTab={activeTab}
            handleOpenBalance={handleOpenBalance}
            dataLoader={dataLoader}
            allBalance={allBalance}
            handleOpenConfirmation={handleOpenConfirmation}
          />
        )}

        <div className={styles.paginationContainer}>
          {totalPage > 1 ? (
            <PaginationComponent
              totalPages={totalPage}
              activePage={activePage}
              setActivePage={setActivePage}
            />
          ) : null}
        </div>
      </div>

      <AddPlan
        open={openForm}
        handleClose={handleCloseForm}
        activeTab={activeTab}
        selectedItem={selectedPlan}
        setSelectedItem={setSelectedPlan}
        getAllPlans={getAllPlans}
        viewOnly={viewOnly}
        editOnly={editOnly}
        isSchoolTabSelected={isSchoolTabSelected}
      />

      <ManagePlan
        open={openAdd}
        activeTab={activeTab}
        handleClose={() => {
          setOpenAdd(false);
          setSelectedItem(null);
        }}
        isSchoolTabSelected={activeTab === planCategories.school}
        selectedItem={selectedItem}
        getAllPlans={getAllPlans}
      />

      <TopUpBalance
        open={openBalance}
        handleClose={handleCloseBalance}
        getAllBalance={getAllBalance}
        selectedBalance={selectedBalance}
        setSelectedBalance={setSelectedBalance}
      />

      <Confirmation
        open={openConfirm}
        handleClose={() => handleCloseConfirmation()}
        handleClick={() => handleActions(action)}
        text={action.label}
      />
      {loader && <Loader />}
    </div>
  );
}

export default Membership;
