import React, { useEffect, useState } from "react";
import styles from "pages/manageBanner/style.module.scss";
import classNames from "classnames";
import DoneIcon from "@mui/icons-material/Done";

interface BannerUserSelectionProps {
  setFieldValue: (field: string, value: any) => void;
  errors: Record<string, any>;
  touched: Record<string, any>;
  values: any;
}

function BannerUserSelection({
  setFieldValue,
  errors,
  touched,
  values,
}: BannerUserSelectionProps) {
  const [selections, setSelections] = useState<any>({
    public: false,
    logged: false,
  });

  useEffect(() => {
    if (typeof values?.user_type !== "string") {
      if (values?.user_type === 0) {
        setSelections({
          public: true,
          logged: false,
        });
      } else if (values?.user_type === 1) {
        setSelections({
          public: false,
          logged: true,
        });
      }
    }

    console.log("values", values);
    //eslint-disable-next-line
  }, []);

  return (
    <div className={styles.section}>
      <label className={styles.label}>Select Banner User Type</label>
      <div style={{ display: "flex", gap: "6px", alignItems: "center" }}>
        <div
          className={classNames(
            styles.checkbox,
            selections?.public && styles.checked
          )}
          onClick={() => {
            setSelections({ logged: false, public: true });
            setFieldValue("user_type", 0);
          }}
        >
          <DoneIcon
            sx={{
              color: "white",
              height: "12px",
              width: "12px",
            }}
          />
        </div>
        <label className={styles.selectionLabel}>Public Users</label>
      </div>
      <div style={{ display: "flex", gap: "6px", alignItems: "center" }}>
        <div
          className={classNames(
            styles.checkbox,
            selections?.logged && styles.checked
          )}
          onClick={() => {
            setSelections({ logged: true, public: false });
            setFieldValue("user_type", 1);
          }}
        >
          <DoneIcon
            sx={{
              color: "white",
              height: "12px",
              width: "12px",
            }}
          />
        </div>
        <label className={styles.selectionLabel}>Logged in Users</label>
      </div>
      <div className={styles.error}>
        {touched?.user_type && errors?.user_type
          ? errors?.user_type.toString()
          : null}
      </div>
    </div>
  );
}

export default BannerUserSelection;
