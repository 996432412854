import { Grid, Skeleton } from "@mui/material";
import React from "react";

function Loader() {
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <div>
            <Skeleton
              height={20}
              width={160}
              sx={{ borderRadius: "16px", marginBottom: "12px" }}
              variant="rounded"
            />
          </div>
          <Skeleton
            sx={{ borderRadius: "16px", height: "110px" }}
            variant="rounded"
          />
        </Grid>
        <Grid item xs={6} lg={3}>
          <div>
            <Skeleton
              height={20}
              sx={{ borderRadius: "16px" }}
              variant="rounded"
            />
          </div>
          <div style={{ display: "flex", gap: "6px", marginTop: "10px" }}>
            <Skeleton height={20} width={20} variant="rounded" />
            <Skeleton height={20} width={100} variant="rounded" />
          </div>
          <div style={{ display: "flex", gap: "6px", marginTop: "10px" }}>
            <Skeleton height={20} width={20} variant="rounded" />
            <Skeleton height={20} width={100} variant="rounded" />
          </div>
        </Grid>
        <Grid item xs={6} lg={3}>
          <div>
            <Skeleton
              height={20}
              sx={{ borderRadius: "16px" }}
              variant="rounded"
            />
          </div>
          <div style={{ display: "flex", gap: "6px", marginTop: "10px" }}>
            <Skeleton
              height={40}
              width={120}
              variant="rounded"
              sx={{ borderRadius: "32px" }}
            />
          </div>
        </Grid>
        <Grid item xs={12} lg={3} marginTop={"20px"}>
          <div>
            <Skeleton
              height={20}
              width={160}
              sx={{ borderRadius: "16px", marginBottom: "12px" }}
              variant="rounded"
            />
          </div>
          <Skeleton
            height={200}
            sx={{ borderRadius: "16px" }}
            variant="rounded"
          />
        </Grid>
        <Grid item xs={12} lg={9} marginTop={"20px"}>
          <div>
            <Skeleton
              height={20}
              width={160}
              sx={{ borderRadius: "16px", marginBottom: "12px" }}
              variant="rounded"
            />
          </div>
          <Skeleton
            height={200}
            sx={{ borderRadius: "16px" }}
            variant="rounded"
          />
        </Grid>{" "}
        <Grid item xs={12} marginTop={"20px"}>
          <div>
            <Skeleton
              height={20}
              width={160}
              sx={{ borderRadius: "16px", marginBottom: "12px" }}
              variant="rounded"
            />
          </div>
          <Skeleton
            height={50}
            sx={{ borderRadius: "16px" }}
            variant="rounded"
          />
        </Grid>
        <Grid item xs={12} marginTop={"40px"}>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Skeleton
              height={40}
              width={120}
              sx={{ borderRadius: "32px", marginBottom: "12px" }}
              variant="rounded"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Loader;
