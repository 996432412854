import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Icons } from "assets";
import classNames from "classnames";
import moment from "moment";
import useWindowSize from "shared/customHooks/windowSize";
import SkeletonLoader from "../skeletonLoader";
import styles from "./../style.module.scss";

function BalanceTable({
  activeTab,
  handleOpenBalance,
  dataLoader,
  allBalance,
  handleOpenConfirmation,
}: any) {
  const screenWidth = useWindowSize();

  return (
    <Box className={styles.topLevelContainer}>
      <TableContainer
        component={Paper}
        className={styles.table}
        sx={screenWidth > 990 ? { width: `${screenWidth - 300}px` } : {}}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className={styles.tableHead}>
              <TableCell>Price</TableCell>
              <TableCell>Coins</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Updated</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataLoader ? (
              <SkeletonLoader activeTab={activeTab} />
            ) : (
              allBalance?.map((item: any) => {
                return (
                  <>
                    <TableRow className={styles.tableRow}>
                      <TableCell>{item?.price}</TableCell>
                      <TableCell>{item?.coins}</TableCell>

                      <TableCell>
                        {moment(item?.created_at).format("D MMMM YYYY")}
                      </TableCell>
                      <TableCell>
                        {moment(item?.updated_at).format("D MMMM YYYY")}
                      </TableCell>
                      <TableCell>
                        <div className={styles.actionContainer}>
                          <Tooltip
                            componentsProps={{
                              tooltip: { className: "tooltip" },
                              arrow: { className: "arrow" },
                            }}
                            placement="top"
                            arrow
                            title="View"
                          >
                            <div
                              onClick={() => {
                                handleOpenBalance(item);
                              }}
                            >
                              <Icons.CreateActionIcon />
                            </div>
                          </Tooltip>
                          <Tooltip
                            componentsProps={{
                              tooltip: { className: "tooltip" },
                              arrow: { className: "arrow" },
                            }}
                            placement="top"
                            arrow
                            title="View"
                          >
                            <div
                              onClick={() =>
                                handleOpenConfirmation(
                                  "Are you sure, you want to delete this?",
                                  "delete",
                                  item
                                )
                              }
                            >
                              <Icons.DeleteIcon />
                            </div>
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box>
        {allBalance?.length === 0 ? (
          <div className={classNames(styles.noDataCard, styles.noData)}>
            <Icons.NoDataIcon />
            <h5>No Data Found</h5>
          </div>
        ) : null}
      </Box>
    </Box>
  );
}

export default BalanceTable;
