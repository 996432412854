import {
  FormControl,
  Grid,
  InputBase,
  MenuItem,
  Select,
  styled,
} from "@mui/material";
import { useState } from "react";
import {
  monthly_plans,
  plan_level_enums,
  plan_status,
  plans_types_enums,
} from "../constant";
import styles from "../style.module.scss";
import DatePicker from "react-datepicker";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    border: "1px solid",
    borderColor: "#adb5bd",
    borderRadius: "32px !important",
    padding: "10px",
    outline: "none",
    background: "white",
  },
}));

const DetailSection = ({ radioSelected, formik }: any) => {
  const { handleChange, values, touched, errors, handleBlur } = formik;

  return (
    <Grid container spacing={2} marginTop={"12px"}>
      <Grid item xs={6}>
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <label className={styles.inputLabel}>Plan duration</label>
          <FormControl
            sx={{
              width: "100%",
            }}
          >
            <Select
              className={styles.selectElement}
              input={<BootstrapInput />}
              name="planDuration"
              renderValue={(selected) => {
                const plan = monthly_plans.find(
                  (plan) => plan.value === selected
                );
                if (selected === 1) {
                  return <div className={styles.label}>1 Month Plan</div>;
                }
                return <div className={styles.label}>{plan?.label}</div>;
              }}
              id="plan-status"
              value={values?.plan_duration?.value || ""}
              onChange={(e: any) => {
                formik.setFieldValue(
                  "plan_duration",
                  monthly_plans.find(
                    (item) => item.value === Number(e.target.value)
                  ) as any
                );
              }}
            >
              {monthly_plans?.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item?.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Grid>
      <Grid item xs={6}>
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <label className={styles.inputLabel}>Coins</label>
          <input
            className={styles.input}
            placeholder="e.g. 10"
            id="coins"
            name="coins"
            type="coins"
            value={values.coins}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched.coins && errors.coins && (
            <div className={styles.errorText}>{errors.coins}</div>
          )}
        </div>
      </Grid>
      <Grid item xs={6}>
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <label className={styles.inputLabel}>
            Amount{" "}
            {values?.level === plan_level_enums.international ||
            values?.level === plan_level_enums.in_app_international
              ? "($ USD)"
              : "(Rs)"}
            *
          </label>
          <input
            className={styles.input}
            placeholder="e.g. 100"
            id="amount"
            name="amount"
            type="amount"
            value={values.amount}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched.amount && errors.amount && (
            <div className={styles.errorText}>{errors.amount}</div>
          )}
        </div>
      </Grid>
      <Grid item xs={6}>
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <label className={styles.inputLabel}>Status</label>
          <FormControl
            sx={{
              minWidth: 120,
              width: "100%",
            }}
          >
            <Select
              className={styles.selectElement}
              input={<BootstrapInput />}
              name="planStatus"
              renderValue={(selected) => {
                const label =
                  selected === 0
                    ? "Inactive"
                    : selected === 1
                    ? "Active"
                    : null;

                return <div className={styles.label}>{label}</div>;
              }}
              id="plan-status"
              value={values?.status?.value}
              onChange={(e: any) => {
                formik.setFieldValue(
                  "status",
                  plan_status.find(
                    (item) => item.value === Number(e.target.value)
                  ) as any
                );
              }}
            >
              {plan_status?.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item?.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Grid>

      {values.plan_type?.value === plans_types_enums.promotional && (
        <>
          <Grid item xs={6}>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <label className={styles.inputLabel}>Promotional Title</label>
              <input
                className={styles.input}
                placeholder="e.g. Eid Special"
                id="promotionalTitle"
                name="promotionalTitle"
                type="promotionalTitle"
                value={formik?.values?.promotionalTitle}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik?.touched?.promotionalTitle &&
                formik?.errors?.promotionalTitle && (
                  <div className={styles.errorText}>
                    {formik?.errors?.promotionalTitle}
                  </div>
                )}
            </div>
          </Grid>
          <Grid xs={6} item>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <label className={styles.inputLabel}>Start Date</label>
              <DatePicker
                selected={formik.values.startDate}
                onChange={(date: Date) => {
                  formik.setFieldValue("startDate", date);
                  formik.setFieldValue("endDate", null);
                }}
                format="DD-MM-YYYY"
                minDate={new Date()}
                className={styles.inputField}
              />
              {formik.touched.startDate && formik.errors.startDate && (
                <div className={styles.errorText}>
                  {formik.errors.startDate}
                </div>
              )}
            </div>
          </Grid>
          <Grid xs={6} item>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <label className={styles.inputLabel}>End Date</label>
              <DatePicker
                selected={formik.values.endDate}
                onChange={(date: Date) => {
                  formik.setFieldValue("endDate", date);
                }}
                minDate={new Date()}
                className={styles.inputField}
                format="DD-MM-YYYY"
              />
              {formik.touched.endDate && formik.errors.endDate && (
                <div className={styles.errorText}>{formik.errors.endDate}</div>
              )}
            </div>
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <label className={styles.inputLabel}>Benefits</label>
          <textarea
            className={styles.textarea}
            id="benefits"
            name="benefits"
            value={values.benefits}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched.benefits && errors.benefits && (
            <div className={styles.errorText}>{errors.benefits}</div>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default DetailSection;
