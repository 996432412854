import React, { useEffect, useRef, useState } from "react";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import { routeConstant } from "shared/routes/routesConstant";
import styles from "./style.module.scss";
import { Grid, Skeleton, Tooltip } from "@mui/material";
import { Icons, Images } from "assets";
import moment from "moment";
import { Switch, SwitchProps, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  deleteAnnouncementService,
  getAllBannerService,
  updateAnnouncementStatus,
} from "shared/services/productManagerService";
import { toastMessage } from "shared/components/toast";
import Confirmation from "shared/modals/confirmation";
import ViewBanner from "shared/modals/viewBanner";
import { useOnScroll } from "shared/customHooks/useOnScroll";
import { Height } from "@mui/icons-material";

let user_type = {
  public: 0,
  loggedin: 1,
};

function BannerControl() {
  const navigate = useNavigate();
  const bannersRef = useRef<any[]>([]);
  const pageRef = useRef<number>(1);
  const [allBanners, setAllBanners] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [endReach, onScroll] = useOnScroll("announcementDiv");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number | null>(null);

  useEffect(() => {
    if (endReach && currentPage !== lastPage && !loader) {
      pageRef.current++;
      getAllBanner();
    }
    // eslint-disable-next-line
  }, [endReach, currentPage, lastPage]);

  useBreadcrumbs({
    title: "Announcements Banners",
    path: routeConstant.bannerControl.path,
  });

  const getAllBanner = () => {
    setLoader(true);
    getAllBannerService(pageRef.current)
      .then(
        ({
          data: {
            data: { data, last_page, current_page },
          },
        }) => {
          setLoader(false);
          let temp = [...bannersRef.current, ...data];
          bannersRef.current = temp;
          setAllBanners(temp);
          setLastPage(last_page);
          setCurrentPage(current_page);
        }
      )
      .catch(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    getAllBanner();
  }, []);

  const handleDelete = () => {
    pageRef.current = 1;
    bannersRef.current = [];
    setAllBanners([]);
    getAllBanner();
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.headingContainer}>
        <label className={styles.heading}>Control Website Banners</label>
        <button
          className={styles.addNewBtn}
          onClick={() => navigate(routeConstant.manageBanner.path)}
        >
          Add New
        </button>
      </div>
      <Grid
        container
        spacing={3}
        id="announcementDiv"
        onScroll={onScroll}
        className={styles.mainGrid}
        style={allBanners?.length > 4 ? { height: "80vh" } : {}}
      >
        {loader ? (
          <>
            {Array.from({ length: 6 }).map((item, index) => {
              return (
                <Grid item xs={12} xl={6} lg={12} md={12}>
                  <Loader />
                </Grid>
              );
            })}
          </>
        ) : null}

        {!loader && !allBanners?.length ? (
          <div className={styles.noDataCard}>
            <Icons.NoDataIcon />
            <label>No Data Found</label>
          </div>
        ) : (
          allBanners?.map((item: any, index: any) => {
            return (
              <React.Fragment key={index}>
                <Grid item xs={12} xl={6} lg={12} md={12}>
                  <BannerCard item={item} handleCallback={handleDelete} />
                </Grid>
              </React.Fragment>
            );
          })
        )}
      </Grid>
    </div>
  );
}

export default BannerControl;

const BannerCard = ({ item, handleCallback }: any) => {
  const navigate = useNavigate();
  const [checked, setChecked] = React.useState(item?.status);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openViewBanner, setOpenViewBanner] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);

    updateAnnouncementStatus({
      id: item?.id,
      status: event.target.checked ? 1 : 0,
    })
      .then(({ data: { status, message } }) => {
        if (status) {
          toastMessage("success", message);
        } else {
          toastMessage("error", message);
        }
      })
      .catch((err) => {
        console.log("err", err?.response?.data?.message);
        toastMessage("error", err?.response?.data?.message);
      });
  };

  //   console.log("checked", item);

  const handleDelete = () => {
    deleteAnnouncementService(item?.id)
      .then(({ data: { status, message } }) => {
        if (status) {
          toastMessage("success", message);
        } else {
          toastMessage("error", message);
        }
        setOpenDeleteModal(false);
        handleCallback();
      })
      .catch((err) => {
        console.log("err", err?.response?.data?.message);
      });
  };

  return (
    <>
      <div className={styles.bannerContainer}>
        <div>
          <img
            alt="banner-img"
            src={item?.banner_image ? item?.banner_image : Images.BookSample1}
            className={styles.bannerImg}
          />
        </div>
        <div className={styles.innerContainer}>
          <div
            style={{ display: "flex", gap: "12px", flexDirection: "column" }}
          >
            <div className={styles.actionTopContainer}>
              <div style={{ display: "flex", gap: "5px" }}>
                {item?.user_type === user_type.loggedin ? (
                  <label className={styles.loginLabel}>Login Account</label>
                ) : (
                  <label className={styles.publicLabel}>Public Account</label>
                )}
              </div>
              <div>
                <SwitchCustom checked={checked} onChange={handleChange} />
              </div>
            </div>
            <div className={styles.bannerHeadingContainer}>
              <label className={styles.bannerTitle}>
                <div
                  dangerouslySetInnerHTML={{ __html: item?.title }}
                  className={styles.htmlTitle}
                />
              </label>
              <label className={styles.bannerDate}>
                {moment(item?.created_at).format("MMMM DD, YYYY")}
              </label>
            </div>
          </div>
          <div className={styles.actionContainer}>
            <Tooltip
              componentsProps={{
                tooltip: { className: "tooltip" },
                arrow: { className: "arrow" },
              }}
              placement="top"
              arrow
              title="View"
            >
              <div onClick={() => setOpenViewBanner(true)}>
                <Icons.EyeIcon2 />
              </div>
            </Tooltip>

            <Tooltip
              componentsProps={{
                tooltip: { className: "tooltip" },
                arrow: { className: "arrow" },
              }}
              placement="top"
              arrow
              title="Delete"
            >
              <div onClick={() => setOpenDeleteModal(true)}>
                <Icons.DeleteIcon />
              </div>
            </Tooltip>

            <Tooltip
              componentsProps={{
                tooltip: { className: "tooltip" },
                arrow: { className: "arrow" },
              }}
              placement="top"
              arrow
              title="Edit"
            >
              <div
                onClick={() => {
                  navigate(
                    routeConstant?.manageBanner?.path + `?id=${item?.id}`
                  );
                }}
              >
                <Icons.CreateActionIcon />
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
      <Confirmation
        text={"Are you sure, You want to delete this?"}
        handleClose={() => setOpenDeleteModal(false)}
        open={openDeleteModal}
        handleClick={() => handleDelete()}
      />

      <ViewBanner
        open={openViewBanner}
        handleClose={() => setOpenViewBanner(false)}
        item={item}
      />
    </>
  );
};

// Custom styled switch for iOS-like appearance
const SwitchCustom = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 1,
    margin: 1,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#9a469b" : "#9a469b",
        opacity: 1,
        border: 0,
      },
      "& .MuiSwitch-thumb": {
        boxShadow: "0 3px 8px rgba(0, 0, 0, 0.24)",
      },
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.5,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "dark" ? "#39393D" : "#d7d7d7e3",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const Loader = () => {
  return (
    <div className={styles.bannerLoaderContainer}>
      <div>
        <Skeleton
          variant="rounded"
          sx={{ height: "200px", width: "300px", borderRadius: "16px" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <div>
              <Skeleton
                variant="rounded"
                sx={{ height: "20px", width: "140px", borderRadius: "16px" }}
              />
            </div>
            <div>
              <Skeleton
                variant="rounded"
                sx={{ height: "30px", width: "240px", borderRadius: "16px" }}
              />
            </div>
            <div>
              <Skeleton
                variant="rounded"
                sx={{ height: "20px", width: "140px", borderRadius: "16px" }}
              />
            </div>
          </div>
          <div>
            <Skeleton
              variant="rounded"
              sx={{ height: "30px", width: "60px", borderRadius: "32px" }}
            />
          </div>
        </div>
        <div style={{ display: "flex", gap: "6px" }}>
          <Skeleton variant="circular" sx={{ height: "45px", width: "45px" }} />
          <Skeleton variant="circular" sx={{ height: "45px", width: "45px" }} />
          <Skeleton variant="circular" sx={{ height: "45px", width: "45px" }} />
        </div>
      </div>
    </div>
  );
};
