import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Icons } from "assets";
import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toastMessage } from "shared/components/toast";
import {
  createPlanService,
  updatePlanService,
} from "shared/services/productManagerService";
import * as yup from "yup";
import CardsSection from "./cardsSection";
import {
  monthly_plans,
  plan_category_enums,
  plan_level_types,
  plan_status,
  plans_types,
  plans_types_enums,
} from "./constant";
import DetailSection from "./detailSelection";
import DiscountSection from "./discountSection";
import LevelSelection from "./levelSelection";
import PlanTypeSelection from "./planTypeSelection";
import SchoolSelection from "./schoolSelection";
import styles from "./style.module.scss";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface ManagePlanProps {
  open: boolean;
  handleClose: () => void;
  isSchoolTabSelected: any;
  selectedItem: any;
  activeTab: any;
  getAllPlans: any;
}

export default function ManagePlan({
  open,
  handleClose,
  isSchoolTabSelected,
  selectedItem,
  activeTab,
  getAllPlans,
}: ManagePlanProps) {
  const [isDiscounted, setIsDiscounted] = useState<boolean>(false);
  const [isPromotional, setIsPromotional] = useState<boolean>(false);

  const onClose = () => {
    handleClose();
    setIsDiscounted(false);
    setIsPromotional(false);
    formik?.resetForm();
  };

  const initialValues: any = {
    coins: selectedItem ? Number(selectedItem?.coins) : "",
    amount: "",
    benefits: "",
    discountTitle: "",
    discountAmount: "",
    startDate: null,
    endDate: null,
    promotionalTitle: "",
    school: "",
    mobile_card: "",
    web_card: "",
    plan_type: plans_types[0],
    plan_duration: monthly_plans[0],
    status: plan_status[1],
    level: plan_level_types[1].value,
    is_discounted: false,
  };

  const getValidationSchema = () => {
    const commonSchema = {
      coins: yup
        .string()
        .required("Coins is required")
        .matches(/^\d+$/, "Coins must contain positive number"),
      amount: yup
        .string()
        .required("Amount is required")
        .matches(/^\d+(\.\d+)?$/, "Amount must contain a positive number"),
      benefits: yup.string().required("Benefits is required"),
    };

    if (isSchoolTabSelected && !selectedItem) {
      return yup.object({
        ...commonSchema,
        school: yup.object().required("School is required"),
      });
    }

    if (isPromotional) {
      return yup.object({
        ...commonSchema,
        promotionalTitle: yup.string().required("Title is required"),
        startDate: yup.string().required("Start date is required"),
        endDate: yup.string().required("End date is required"),
        mobile_card: yup.mixed().required("Mobile Card is required"),
        web_card: yup.mixed().required("Web Card is required."),
      });
    }

    return isDiscounted
      ? yup.object({
          ...commonSchema,
          discountTitle: yup.string().required("Title is required"),
          startDate: yup.string().required("Start date is required"),
          endDate: yup.string().required("End date is required"),
          discountAmount: yup
            .string()
            .required("Discount Amount is required")
            .matches(/^\d+$/, "Amount must contain positive numbers"),
        })
      : yup.object(commonSchema);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: getValidationSchema(),
    onSubmit: (values) => {
      let resp: any = {
        category: activeTab,
        type: values?.plan_type?.value,
        duration: values?.plan_duration?.value,
        price: Number(values?.amount),
        coins: Number(values?.coins),
        description: values?.benefits,
        status: values?.status.value,
        level: Number(values?.level),
      };

      if (isDiscounted && !isPromotional) {
        resp["discounted_price"] = Number(values?.discountAmount);
        resp["is_discounted"] = isDiscounted ? 1 : 0;
        resp["title"] = values?.discountTitle;
      }

      if (isDiscounted || isPromotional) {
        resp["start_date"] = moment(values?.startDate).format("YYYY-MM-DD");
        resp["end_date"] = moment(values?.endDate).format("YYYY-MM-DD");
      }

      if (isSchoolTabSelected) {
        resp["user_id"] = values?.school?.id;
      }

      if (isPromotional) {
        resp["title"] = values?.promotionalTitle;
        if (values?.web_card instanceof File) {
          resp["web_card"] = values.web_card;
        }
        if (values?.mobile_card instanceof File) {
          resp["mobile_card"] = values?.mobile_card;
        }
      }

      if (selectedItem) {
        resp["plan_id"] = selectedItem?.id;
      }

      console.log("resp", resp);

      let formBody = new FormData();

      Object.keys(resp).forEach((key) => {
        formBody.append(key, resp[key]);
      });

      let service = selectedItem ? updatePlanService : createPlanService;

      service(formBody)
        .then(({ data: { status, message } }) => {
          if (status) {
            toastMessage("success", message);
            onClose();
            getAllPlans();
          } else {
            toastMessage("error", message);
          }
        })
        .catch((err) => {
          console.log("err", err?.response?.data?.message);
          toastMessage("error", err?.response?.data?.message);
          // setLoader(false);
        });
    },
  });

  const { handleSubmit, values } = formik;

  useEffect(() => {
    if (selectedItem) {
      formik.setFieldValue("coins", Number(selectedItem?.coins));
      formik.setFieldValue("amount", Number(selectedItem?.price));
      formik.setFieldValue(
        "status",
        plan_status.find((item) => selectedItem?.status === item.value)
      );
      formik.setFieldValue(
        "plan_duration",
        monthly_plans.find((item) => item?.value === selectedItem?.duration)
      );

      formik.setFieldValue("benefits", selectedItem.description.join("\n"));
      formik.setFieldValue(
        "plan_type",
        plans_types.find((item) => item?.value === selectedItem?.type)
      );
      formik.setFieldValue("level", selectedItem?.level);
      formik.setFieldValue("is_discounted", selectedItem?.is_discounted);

      if (selectedItem?.is_discounted) {
        setIsDiscounted(true);
        formik.setFieldValue(
          "discountAmount",
          Number(selectedItem?.discounted_price)
        );
        formik.setFieldValue("discountTitle", selectedItem?.title);
        formik.setFieldValue(
          "startDate",
          selectedItem?.start_date ? new Date(selectedItem?.start_date) : null
        );
        formik.setFieldValue(
          "endDate",
          selectedItem?.end_date ? new Date(selectedItem?.end_date) : null
        );
      }

      if (selectedItem?.school_name) {
      }

      if (Number(selectedItem?.type) === plans_types_enums.promotional) {
        setIsPromotional(true);
        formik.setFieldValue("promotionalTitle", selectedItem?.title);
        formik.setFieldValue(
          "startDate",
          selectedItem?.start_date ? new Date(selectedItem?.start_date) : null
        );
        formik.setFieldValue(
          "endDate",
          selectedItem?.end_date ? new Date(selectedItem?.end_date) : null
        );
        formik.setFieldValue(
          "web_card",
          selectedItem?.web_card ? selectedItem?.web_card : null
        );
        formik.setFieldValue(
          "mobile_card",
          selectedItem?.mobile_card ? selectedItem?.mobile_card : null
        );
      }

      if (selectedItem?.user_id || selectedItem?.school_name) {
        formik.setFieldValue("school", {
          id: selectedItem?.user_id,
        });
      }
    }
    // eslint-disable-next-line
  }, [selectedItem]);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: (theme) => theme.spacing(105),
              position: "relative",
            },
          },
        }}
      >
        <DialogContent className={styles.dialogContainer}>
          <div className={styles.iconContainer}>
            <Icons.BookCloseIcon
              className={styles.closeIcon}
              onClick={() => onClose()}
            />
          </div>

          <div className={styles.mainContainer}>
            <label className={styles.title}>
              For
              {activeTab === plan_category_enums.individual
                ? " Individual "
                : activeTab === plan_category_enums.family
                ? " Family "
                : activeTab === plan_category_enums.school
                ? " School "
                : ""}
              Account
            </label>

            <div className={styles.radioLabelContainer}>
              <label className={styles.subLabel}>Add details</label>
              <PlanTypeSelection
                selectedItem={selectedItem}
                formik={formik}
                setIsPromotional={setIsPromotional}
              />
            </div>

            <div className={styles.infoContainer}>
              {activeTab !== plan_category_enums.school && (
                <LevelSelection formik={formik} selectedItem={selectedItem} />
              )}
              <DetailSection formik={formik} />
            </div>

            {isSchoolTabSelected && (
              <div>
                <SchoolSelection formik={formik} selectedItem={selectedItem} />
              </div>
            )}

            {!isSchoolTabSelected &&
              values?.plan_type?.value === plans_types_enums.regular && (
                <div>
                  <DiscountSection
                    isDiscounted={isDiscounted}
                    setIsDiscounted={setIsDiscounted}
                    formik={formik}
                  />
                </div>
              )}

            {isPromotional && (
              <CardsSection formik={formik} selectedItem={selectedItem} />
            )}

            <div className={styles.btnContainer}>
              <button
                className={styles.addBtn}
                onClick={() => {
                  handleSubmit();
                }}
              >
                {selectedItem ? "Update" : "Add"}
              </button>
              <button
                className={styles.cancelBtn}
                onClick={() => handleClose()}
              >
                Cancel
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
