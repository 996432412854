import AllMou from "pages/allMou";
import Auth from "pages/auth";
import Authors from "pages/authors";
import BookDetail from "pages/bookDetail";
import BookReviewer from "pages/bookReviewer";
import BookReviewers from "pages/bookReviewers";
import Books from "pages/books";
import BooksCatalog from "pages/booksCatalog";
import BooksListing from "pages/booksListing";
import Chats from "pages/chat";
import CustomeSupport from "pages/customerSupport";
import DashboardAccountant from "pages/dashboardAccountant";
import DashboardAdmin from "pages/dashboardAdmin";
import DashboardBookReviewer from "pages/dashboardBookReviewer";
import DashboardHead from "pages/dashboardHead";
import DashboardHOD from "pages/dashboardHOD";
import DashboardManager from "pages/dashboardManager";
import FeaturedBooks from "pages/featuredBooks";
import HardCopy from "pages/hardCopy";
import Membership from "pages/membership";
import MOU from "pages/mou";
import PublisherProfile from "pages/publisherProfile";
import Publishers from "pages/publishers";
import RequiredBooks from "pages/reportedBooks";
import Review from "pages/review";
import ReviewByHOD from "pages/reviewHOD";
import Settings from "pages/settings";
import Ticket from "pages/singleTicket";
import SoftCopy from "pages/softCopy";
import Tickets from "pages/tickets";
import Transactions from "pages/transactions";
import { routeConstant } from "./routesConstant";
// import BestSellers from "pages/bestSeller";
import Accounts from "pages/account";
import TermsAndPolicy from "pages/agreement";
import AliflailaUsers from "pages/aliflailaUser";
import AuthorProfile from "pages/authorProfile";
import Banners from "pages/banner";
import BookPreview from "pages/bookPreview";
import BookRevisions from "pages/bookRevisions";
import MOUSignature from "pages/mouSignature";
import NewsLetterSubscriptions from "pages/newsletterSubscriptions";
import Parents from "pages/parents";
import PartnerAccount from "pages/partnersAccount";
import ReportBookDetails from "pages/reportBookDetails";
import ReportDetails from "pages/reportDetail";
import ReportedUsers from "pages/reportedUsers";
import ReviewerPerformance from "pages/reviewerPerformance";
import ReviewersPerformace from "pages/reviewersPerformance";
import Sales from "pages/sales";
import SchoolAccounts from "pages/schoolAccounts";
import SchoolRequests from "pages/schoolRequests";
import SchoolTickets from "pages/schoolTickets";
import SubscriptionRequest from "pages/subscriptionRequest";
import Subscriptions from "pages/subscriptions";
import TopSales from "pages/topSales";
import TransactionHistory from "pages/transactionHistory";
import Notifications from "pages/notifications";
import CustomNotifications from "pages/customNotifications";
import NotificationListings from "pages/notificationListings";
import BannerControl from "pages/bannerControl";
import ManageBanner from "pages/manageBanner";

const publicRoutes = [
  {
    path: routeConstant.auth.path,
    title: routeConstant.auth.title,
    Component: Auth,
  },
];

const commonRoutes = [
  {
    path: routeConstant.settings.path,
    title: routeConstant.settings.title,
    Component: Settings,
  },
  {
    path: routeConstant.bookPreview.path,
    title: routeConstant.bookPreview.title,
    Component: BookPreview,
  },
  {
    path: routeConstant.notifications.path,
    title: routeConstant.notifications.title,
    Component: Notifications,
  },
];

const adminRoutes = [
  ...commonRoutes,
  {
    path: routeConstant.dashboard.path,
    title: routeConstant.dashboard.title,
    Component: DashboardAdmin,
  },
  {
    path: routeConstant.tickets.path,
    title: routeConstant.tickets.title,
    Component: Tickets,
  },
  {
    path: routeConstant.publishers.path,
    title: routeConstant.publishers.title,
    Component: Publishers,
  },
  {
    path: routeConstant.chat.path,
    title: routeConstant.chat.title,
    Component: Chats,
  },
  {
    path: routeConstant.books.path,
    title: routeConstant.books.title,
    Component: Books,
  },
  {
    path: routeConstant.author.path,
    title: routeConstant.author.title,
    Component: Authors,
  },
  {
    path: routeConstant.bookReviewer.path,
    title: routeConstant.bookReviewer.title,
    Component: BookReviewers,
  },
  {
    path: routeConstant.publisherProfile.path,
    title: routeConstant.publisherProfile.title,
    Component: PublisherProfile,
  },
  {
    path: routeConstant.singleAuthor.path,
    title: routeConstant.singleAuthor.title,
    Component: AuthorProfile,
  },
  {
    path: routeConstant.mou.path,
    title: routeConstant.mou.title,
    Component: MOU,
  },
  {
    path: routeConstant.singleTicket.path,
    title: routeConstant.singleTicket.title,
    Component: Ticket,
  },
  {
    path: routeConstant.bookDetail.path,
    title: routeConstant.bookDetail.title,
    Component: BookDetail,
  },
  // School Tabs
  {
    path: routeConstant.schoolAccounts.path,
    title: routeConstant.schoolAccounts.title,
    Component: SchoolAccounts,
  },
  {
    path: routeConstant.schoolRequests.path,
    title: routeConstant.schoolRequests.title,
    Component: SchoolRequests,
  },
  {
    path: routeConstant.schoolTickets.path,
    title: routeConstant.schoolTickets.title,
    Component: SchoolTickets,
  },
  {
    path: routeConstant.bookReview.path,
    title: routeConstant.bookReview.title,
    Component: BookReviewer,
  },
];

const HODRoutes = [
  ...commonRoutes,
  {
    path: routeConstant.dashboard.path,
    title: routeConstant.dashboard.title,
    Component: DashboardHOD,
  },
  {
    path: routeConstant.bookDetail.path,
    title: routeConstant.bookDetail.title,
    Component: BookDetail,
  },
  {
    path: routeConstant.bookReviewer.path,
    title: routeConstant.bookReviewer.title,
    Component: BookReviewers,
  },
  {
    path: routeConstant.bookReview.path,
    title: routeConstant.bookReview.title,
    Component: BookReviewer,
  },
  {
    path: routeConstant.books.path,
    title: routeConstant.books.title,
    Component: Books,
  },
  {
    path: routeConstant.reviewByHOD.path,
    title: routeConstant.reviewByHOD.title,
    Component: ReviewByHOD,
  },
  {
    path: routeConstant.review.path,
    title: routeConstant.review.title,
    Component: Review,
  },
  {
    path: routeConstant.bookRevisions.path,
    title: routeConstant.bookRevisions.title,
    Component: BookRevisions,
  },
];

const bookReviewerRoutes = [
  ...commonRoutes,
  {
    path: routeConstant.dashboard.path,
    title: routeConstant.dashboard.title,
    Component: DashboardBookReviewer,
  },
  {
    path: routeConstant.bookDetail.path,
    title: routeConstant.bookDetail.title,
    Component: BookDetail,
  },
  {
    path: routeConstant.books.path,
    title: routeConstant.books.title,
    Component: BooksListing,
  },
  {
    path: routeConstant.review.path,
    title: routeConstant.review.title,
    Component: Review,
  },
  {
    path: routeConstant.bookRevisions.path,
    title: routeConstant.bookRevisions.title,
    Component: BookRevisions,
  },
];

const accountantRoutes = [
  ...commonRoutes,
  {
    path: routeConstant.dashboard.path,
    title: routeConstant.dashboard.title,
    Component: DashboardAccountant,
  },
  {
    path: routeConstant.publisherProfile.path,
    title: routeConstant.publisherProfile.title,
    Component: PublisherProfile,
  },
  {
    path: routeConstant.transactions.path,
    title: routeConstant.transactions.title,
    Component: Transactions,
  },
  {
    path: routeConstant.accounts.path,
    title: routeConstant.accounts.title,
    Component: Accounts,
  },
];

const productManagerRoutes = [
  ...commonRoutes,
  {
    path: routeConstant.dashboard.path,
    title: routeConstant.dashboard.title,
    Component: DashboardManager,
  },
  {
    path: routeConstant.softCopy.path,
    title: routeConstant.softCopy.title,
    Component: SoftCopy,
  },
  {
    path: routeConstant.hardCopy.path,
    title: routeConstant.hardCopy.title,
    Component: HardCopy,
  },
  {
    path: routeConstant.booksCatalog.path,
    title: routeConstant.booksCatalog.title,
    Component: BooksCatalog,
  },
  {
    path: routeConstant.featuredBooks.path,
    title: routeConstant.featuredBooks.title,
    Component: FeaturedBooks,
  },
  {
    path: routeConstant.customerSupport.path,
    title: routeConstant.customerSupport.title,
    Component: CustomeSupport,
  },
  {
    path: routeConstant.membership.path,
    title: routeConstant.membership.title,
    Component: Membership,
  },
  {
    path: routeConstant.requiredBooks.path,
    title: routeConstant.requiredBooks.title,
    Component: RequiredBooks,
  },
  // {
  //   path: routeConstant.school.path,
  //   title: routeConstant.school.title,
  //   Component: School,
  // },
  {
    path: routeConstant.publisherProfile.path,
    title: routeConstant.publisherProfile.title,
    Component: PublisherProfile,
  },
  {
    path: routeConstant.singleTicket.path,
    title: routeConstant.singleTicket.title,
    Component: Ticket,
  },
  {
    path: routeConstant.bookDetail.path,
    title: routeConstant.bookDetail.title,
    Component: BookDetail,
  },
  {
    path: routeConstant.reportBookDetails.path,
    title: routeConstant.reportBookDetails.title,
    Component: ReportBookDetails,
  },
  {
    path: routeConstant.bookRevisions.path,
    title: routeConstant.bookRevisions.title,
    Component: BookRevisions,
  },
  {
    path: routeConstant.sales.path,
    title: routeConstant.sales.title,
    Component: Sales,
  },
  {
    path: routeConstant.subscriptionRequest.path,
    title: routeConstant.subscriptionRequest.title,
    Component: SubscriptionRequest,
  },

  {
    path: routeConstant.subscriptions.path,
    title: routeConstant.subscriptions.title,
    Component: Subscriptions,
  },
  {
    path: routeConstant.privacyPolicy.path,
    title: routeConstant.privacyPolicy.title,
    Component: TermsAndPolicy,
  },
  {
    path: routeConstant.termsAndCondition.path,
    title: routeConstant.termsAndCondition.title,
    Component: TermsAndPolicy,
  },
  {
    path: routeConstant.aliflailaIndividualUsers.path,
    title: routeConstant.aliflailaIndividualUsers.title,
    Component: AliflailaUsers,
  },
  // {
  //   path: routeConstant.individualDetail.path,
  //   title: routeConstant.individualDetail.title,
  //   Component:,
  // },
  {
    path: routeConstant.parentUsers.path,
    title: routeConstant.parentUsers.title,
    Component: Parents,
  },
  {
    path: routeConstant.author.path,
    title: routeConstant.auth.title,
    Component: Authors,
  },
  {
    path: routeConstant.newsletterSubscription.path,
    title: routeConstant.newsletterSubscription.title,
    Component: NewsLetterSubscriptions,
  },
  {
    path: routeConstant.transactionHistory.path,
    title: routeConstant.transactionHistory.title,
    Component: TransactionHistory,
  },
  {
    path: routeConstant.banners.path,
    title: routeConstant.banners.title,
    Component: Banners,
  },
  // {
  //   path: routeConstant.parentDetail.path,
  //   title: routeConstant.parentDetail.title,
  //   Component:,
  // }
  {
    path: routeConstant.customNotifications.path,
    title: routeConstant.customNotifications.title,
    Component: CustomNotifications,
  },
  {
    path: routeConstant.notificationListings.path,
    title: routeConstant.notificationListings.title,
    Component: NotificationListings,
  },
  {
    path: routeConstant.manageBanner.path,
    title: routeConstant.manageBanner.title,
    Component: ManageBanner,
  },
  {
    path: routeConstant.bannerControl.path,
    title: routeConstant.bannerControl.title,
    Component: BannerControl,
  },
];

const projectHeadRoutes = [
  ...commonRoutes,

  {
    path: routeConstant.dashboard.path,
    title: routeConstant.dashboard.title,
    Component: DashboardHead,
  },
  {
    path: routeConstant.allMou.path,
    title: routeConstant.allMou.title,
    Component: AllMou,
  },
  {
    path: routeConstant.transactions.path,
    title: routeConstant.transactions.title,
    Component: Transactions,
  },
  {
    path: routeConstant.bestSellers.path,
    title: routeConstant.bestSellers.title,
    Component: TopSales,
  },
  {
    path: routeConstant.reviewersPerformance.path,
    title: routeConstant.reviewersPerformance.title,
    Component: ReviewersPerformace,
  },
  {
    path: routeConstant.partnerAccount.path,
    title: routeConstant.partnerAccount.title,
    Component: PartnerAccount,
  },
  {
    path: routeConstant.mou.path,
    title: routeConstant.mou.title,
    Component: MOU,
  },
  {
    path: routeConstant.publisherProfile.path,
    title: routeConstant.publisherProfile.title,
    Component: PublisherProfile,
  },
  {
    path: routeConstant.bookDetail.path,
    title: routeConstant.bookDetail.title,
    Component: BookDetail,
  },
  {
    path: routeConstant.reviewerPerformance.path,
    title: routeConstant.reviewerPerformance.title,
    Component: ReviewerPerformance,
  },
  {
    path: routeConstant.reportedUsers.path,
    title: routeConstant.reportedUsers.title,
    Component: ReportedUsers,
  },
  {
    path: routeConstant.reports.path,
    title: routeConstant.reports.title,
    Component: ReportDetails,
  },
  {
    path: routeConstant.mouHead.path,
    title: routeConstant.mouHead.title,
    Component: MOUSignature,
  },
  {
    path: routeConstant.singleAuthor.path,
    title: routeConstant.singleAuthor.title,
    Component: AuthorProfile,
  },
  {
    path: routeConstant.newsletterSubscription.path,
    title: routeConstant.newsletterSubscription.title,
    Component: NewsLetterSubscriptions,
  },
  {
    path: routeConstant.subscriptions.path,
    title: routeConstant.subscriptions.title,
    Component: Subscriptions,
  },
  {
    path: routeConstant.customNotifications.path,
    title: routeConstant.customNotifications.title,
    Component: CustomNotifications,
  },
  {
    path: routeConstant.notificationListings.path,
    title: routeConstant.notificationListings.title,
    Component: NotificationListings,
  },
];

let rolesRoutes = [
  {
    role_id: 1,
    title: "Project Head",
    routes: projectHeadRoutes,
  },
  {
    role_id: 2,
    title: "HOD",
    routes: HODRoutes,
  },
  {
    role_id: 3,
    title: "Book Reviewer",
    routes: bookReviewerRoutes,
  },
  {
    role_id: 4,
    title: "Product Manager",
    routes: productManagerRoutes,
  },
  {
    role_id: 5,
    title: "Accountant",
    routes: accountantRoutes,
  },
  {
    role_id: 6,
    title: "Admin",
    routes: adminRoutes,
  },
];

export {
  accountantRoutes,
  adminRoutes,
  bookReviewerRoutes,
  HODRoutes,
  productManagerRoutes,
  projectHeadRoutes,
  publicRoutes,
  rolesRoutes,
};
