import {
  CircularProgress,
  Grid,
  Switch,
  SwitchProps,
  styled,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AddBannerDetailInfo from "shared/components/bannerControl/addBannerDetailInfo";
import BannerUserSelection from "shared/components/bannerControl/bannerUserSelection";
import ImageTitleSection from "shared/components/bannerControl/imageTitleSection";
import UploadBannerSection from "shared/components/bannerControl/uploadBannerSection";
import { toastMessage } from "shared/components/toast";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import { routeConstant } from "shared/routes/routesConstant";
import {
  addAnnouncementsBannersService,
  getSingleAnnouncement,
  updateAnnouncementService,
} from "shared/services/productManagerService";
import { announcementBannerValidation } from "shared/utils/validationSchemas";
import * as yup from "yup";
import Loader from "./loader";
import styles from "./style.module.scss";

const initialValues = {
  banner_image: "",
  title: "",
  user_type: "",
  is_clickable: "",
  button_image: "",
  external_url: "",
  internal_url: "",
  description: "",
  button_text: "",
};

function ManageBanner() {
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");
  const [clickableChecked, setClickableChecked] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [submitLoader, setSubmitLoader] = useState<boolean>(false);
  const [isButtonImageAdded, setIsButtonImageAdded] = useState<boolean>(false);
  const [externalLinkAdded, setExternalLinkAdded] = useState<boolean>(false);
  const [editerLoader, setEditorLoader] = useState<boolean>(false);

  // for handling update
  useEffect(() => {
    if (id) {
      setLoader(true);
      getSingleAnnouncement(id)
        .then(({ data: { status, data } }) => {
          setLoader(false);
          if (status) {
            setFieldValue("title", data?.title ? data?.title : "");
            setFieldValue("user_type", data?.user_type);
            setFieldValue(
              "is_clickable",
              data?.is_clickable ? data?.is_clickable : 0
            );
            setClickableChecked(Boolean(data?.is_clickable));
            setFieldValue(
              "banner_image",
              data?.banner_image ? data?.banner_image : ""
            );
            setFieldValue(
              "button_image",
              data?.button_image ? data?.button_image : ""
            );
            setIsButtonImageAdded(data?.button_image ? true : false);
            setFieldValue(
              "external_url",
              data?.external_url ? data?.external_url : ""
            );
            setExternalLinkAdded(data?.external_url?.length ? true : false);
            setFieldValue(
              "description",
              data?.description ? data?.description : ""
            );
            setFieldValue(
              "button_text",
              data?.button_text ? data?.button_text : ""
            );
            setFieldValue(
              "internal_url",
              data?.internal_url ? data?.internal_url : ""
            );
          } else {
            navigate(routeConstant.bannerControl.path);
          }
        })
        .catch((err) => {
          console.log("Err", err?.response?.data?.message);
          setLoader(false);
        });
    }
    //eslint-disable-next-line
  }, [id]);

  // formik handling

  const getValidationSchema = () => {
    if (clickableChecked) {
      return announcementBannerValidation.shape({
        description: yup.string().required("Detail is required"),
        button_text: yup.string().required("Button text is required"),
        internal_url: yup.string().trim().required("Slug is required"),
        button_image: yup.string().required("Button image is required"),
        external_url: yup.string().required("External link is required"),
      });
    }

    if (isButtonImageAdded) {
      return announcementBannerValidation.shape({
        external_url: yup
          .string()
          .url("Must be a valid URL")
          .required("External link is required"),
      });
    }

    if (!clickableChecked && externalLinkAdded) {
      return announcementBannerValidation.shape({
        button_image: yup.string().required("Button image is required"),
      });
    }

    return announcementBannerValidation;
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: getValidationSchema(),
    onSubmit: (values) => {
      let {
        banner_image,
        title,
        user_type,
        description,
        button_text,
        internal_url,
        external_url,
        button_image,
      } = values;

      let resp: any = {
        user_type,
        title,
        is_clickable: clickableChecked ? 1 : 0,
        status: 1,
      };

      if (typeof banner_image !== "string") {
        resp["banner_image"] = banner_image;
      }

      if (isButtonImageAdded) {
        resp["external_url"] = external_url;

        if (typeof button_image !== "string") {
          resp["button_image"] = button_image;
        }
      }

      if (clickableChecked) {
        resp["description"] = description;
        resp["button_text"] = button_text;
        resp["internal_url"] = internal_url
          .trim()
          .replace(/\s+/g, "-")
          .replace(/[^a-z0-9_]+/gi, "-");
        resp["external_url"] = external_url;

        if (typeof button_image !== "string") {
          resp["button_image"] = button_image;
          console.log("banner_image", banner_image);
        }
      }

      console.log("resp", resp);

      let service = id
        ? () => updateAnnouncementService(id, resp)
        : () => addAnnouncementsBannersService(resp);

      setSubmitLoader(true);
      service()
        .then(({ data: { data, status, message } }) => {
          if (status) {
            setSubmitLoader(false);
            if (!id) {
              navigate(routeConstant.bannerControl.path);
            }
            toastMessage("success", message);
          } else {
            toastMessage("error", message);
          }
          console.log("data", data);
        })
        .catch((err) => {
          toastMessage("error", err?.response?.data?.message);
          setSubmitLoader(false);
        });
    },
  });

  const { handleSubmit, values, touched, errors, setFieldValue } = formik;

  //

  const handleClickableChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setClickableChecked(!clickableChecked);
    setFieldValue("is_clickable", !clickableChecked);
    // if (values?.external_url?.length > 0) {
    //   setFieldValue("internal_url", values?.external_url);
    //   setFieldValue("external_url", "");
    // }
  };

  useBreadcrumbs(
    {
      title: "Manage Banner",
      path: routeConstant.manageBanner.path,
    },
    true
  );

  // console.log("isButtonImageAdded", isButtonImageAdded);
  // console.log("values", values);

  return (
    <div className={styles.mainContainer}>
      <label className={styles.heading}>
        {!id ? "Add New Banner" : "Update Banner"}
      </label>
      <div className={styles.manageContainer}>
        {loader ? (
          <Loader />
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <ImageTitleSection
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                values={values}
              />
            </Grid>
            <Grid item xs={6} lg={3}>
              <BannerUserSelection
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                values={values}
              />
            </Grid>
            <Grid item xs={6} lg={3}>
              <InfoClickableOption
                check={clickableChecked}
                handleChange={handleClickableChange}
              />
            </Grid>
            <Grid item xs={12}>
              <UploadBannerSection
                setFieldValue={setFieldValue}
                errors={errors}
                clickableChecked={clickableChecked}
                touched={touched}
                values={values}
                setIsButtonImageAdded={setIsButtonImageAdded}
                setExternalLinkAdded={setExternalLinkAdded}
              />
            </Grid>
            {clickableChecked ? (
              <Grid item xs={12}>
                <AddBannerDetailInfo
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  values={values}
                  setEditorLoader={setEditorLoader}
                />
              </Grid>
            ) : null}

            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "end",
                margin: "24px 0px",
              }}
            >
              <button
                className={styles.addBtn}
                onClick={() => {
                  handleSubmit();
                }}
                disabled={submitLoader || editerLoader}
              >
                {submitLoader ? (
                  <CircularProgress
                    sx={{
                      color: "white",
                      height: "17px !important",
                      width: "17px !important",
                    }}
                  />
                ) : (
                  `${id ? "Update Banner" : "Add New Banner"}`
                )}
              </button>
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
}

export default ManageBanner;

const SwitchCustom = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 1,
    margin: 1,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#9a469b" : "#9a469b",
        opacity: 1,
        border: 0,
      },
      "& .MuiSwitch-thumb": {
        boxShadow: "0 3px 8px rgba(0, 0, 0, 0.24)",
      },
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.5,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "dark" ? "#39393D" : "#d7d7d7e3",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const InfoClickableOption = ({ check, handleChange }: any) => {
  return (
    <div className={styles.section}>
      <label className={styles.label}>Make Clickable</label>
      <div>
        <SwitchCustom checked={check} onChange={handleChange} />
      </div>
    </div>
  );
};
