import AddIcon from "@mui/icons-material/Add";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import Loader from "shared/components/loader/animation";
import { toastMessage } from "shared/components/toast";
import Confirmation from "shared/modals/confirmation";
import {
  addBanner,
  deleteBanner,
  getBannerListings,
} from "shared/services/productManagerService";
import styles from "./style.module.scss";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import { routeConstant } from "shared/routes/routesConstant";

interface Image {
  url: string;
}

const Banners: React.FC = () => {
  const [allImages, setAllImages] = useState<Image[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [selectedPicture, setSelectedPicture] = useState<any>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [allBanners, setAllBanners] = useState<any>([]);

  const getAllBanners = (loader: any) => {
    if (loader) {
      setLoader(true);
    }
    getBannerListings()
      .then(({ data: { data } }) => {
        setLoader(false);
        setAllBanners(data);
      })
      .catch((err) => {
        setLoader(false);
        toastMessage("error", err?.message);
      });
  };

  const handleClickOpen = (item: Image) => {
    setOpen(true);
    setSelectedPicture(item);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedPicture(null);
  };

  const handleImageUpload = (newImage: Image) => {
    setAllImages((prevImages) => [...prevImages, newImage]);
  };

  const handleDelete = () => {
    setDeleteLoader(true);
    deleteBanner(selectedPicture?.id)
      .then(({ data: { status, message } }) => {
        setOpen(false);
        if (status) {
          toastMessage("success", message);
          getAllBanners(false);
        } else {
          toastMessage("error", message);
        }
        setDeleteLoader(false);
      })
      .catch((err) => {
        setOpen(false);
        toastMessage("error", err?.response?.data?.message);
        setDeleteLoader(false);
      });
  };

  useEffect(() => {
    getAllBanners(loader);
  }, []);

  useBreadcrumbs({
    title: "Banners",
    path: routeConstant.banners.path,
  });

  return (
    <div>
      <label className={styles.title}>Add Images to Mobile Welcome Page</label>
      <div className={styles.subHeading}>
        Banner should adheres to the specified dimensions of 428 x 474
      </div>

      <div className={styles.imageContainer}>
        {loader ? (
          <>
            {Array.from(Array(3).keys()).map((_, index) => (
              <React.Fragment key={index}>
                <Skeleton variant="rectangular" className={styles.loader} />
              </React.Fragment>
            ))}
          </>
        ) : (
          allBanners?.map((item: any, index: any) => (
            <div key={index} style={{ position: "relative" }}>
              <img src={item.image} className={styles.preview} alt="Preview" />

              <HighlightOffIcon
                sx={{
                  position: "absolute",
                  top: "10px",
                  right: "20px",
                  cursor: "pointer",
                  color: "#9A469B",
                }}
                onClick={() => handleClickOpen(item)}
              />
            </div>
          ))
        )}

        <ImageUploader
          handleImageUpload={handleImageUpload}
          getAllBanners={getAllBanners}
        />
      </div>
      <Confirmation
        open={open}
        handleClose={handleClose}
        handleClick={handleDelete}
        text="Are you sure, you want to delete this banner?"
      />
      {deleteLoader && <Loader />}
    </div>
  );
};

export default Banners;

interface ImageUploaderProps {
  handleImageUpload: (newImage: Image) => void;
  getAllBanners: any;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({
  handleImageUpload,
  getAllBanners,
}) => {
  const [preview, setPreview] = useState<string | null>(null);
  const [rawImage, setRawImage] = useState<File | null>(null);
  const [addLoader, setAddLoader] = useState<boolean>(false);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result) {
          // setPreview(reader.result.toString());
          // setRawImage(file);
          // handleImageUpload({ url: reader.result.toString() });

          let resp: any = { image: file, type: "1" };
          let formBody = new FormData();
          Object.keys(resp).forEach((key) => {
            formBody.append(key, resp[key]);
          });
          setAddLoader(true);

          addBanner(formBody)
            .then(({ data: { status, message } }) => {
              setAddLoader(false);
              if (status) {
                toastMessage("success", message);
                getAllBanners();
              } else {
                toastMessage("error", message);
              }
              getAllBanners(false);
            })
            .catch((err) => {
              toastMessage("err", err.response?.data?.message);
              setAddLoader(false);
            });
        }
      };
      reader.readAsDataURL(file);
    } else {
      setPreview(null);
      setRawImage(null);
    }
  };

  return (
    <div>
      <input
        type="file"
        id="imageInput"
        accept="image/*"
        multiple
        style={{ display: "none" }}
        onChange={handleImageChange}
      />
      <label htmlFor="imageInput">
        <div className={styles.singleContainer}>
          <AddIcon style={{ color: "#ADB5BD" }} />
        </div>
      </label>
      {addLoader && <Loader />}
    </div>
  );
};
