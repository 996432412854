const plans_types = [
  {
    label: "Regular",
    value: 1,
  },
  {
    label: "Promotional",
    value: 2,
  },
];

const plan_category_enums = {
  individual: 1,
  family: 2,
  school: 3,
  balance: 4,
};

const plans_types_enums = {
  regular: 1,
  promotional: 2,
};

const plan_level_types = [
  {
    label: "In App",
    value: 2,
  },
  {
    label: "General",
    value: 1,
  },
  {
    label: "International",
    value: 3,
  },
  {
    label: "In App International",
    value: 4,
  },
];

const plan_level_enums = {
  genera: 1,
  inapp: 2,
  international: 3,
  in_app_international: 4,
};

const monthly_plans = [
  {
    label: "Monthly plan",
    value: 1,
  },
  {
    label: "2-Months plan",
    value: 2,
  },
  {
    label: "3-Months plan",
    value: 3,
  },
  {
    label: "4-Months plan",
    value: 4,
  },
  {
    label: "5-Months plan",
    value: 5,
  },
  {
    label: "6-Months plan",
    value: 6,
  },
  {
    label: "7-Months plan",
    value: 7,
  },
  {
    label: "8-Months plan",
    value: 8,
  },
  {
    label: "9-Months plan",
    value: 9,
  },
  {
    label: "10-Months plan",
    value: 10,
  },
  {
    label: "11-Months plan",
    value: 11,
  },
  {
    label: "Annual Plan",
    value: 12,
  },
];

const plan_status = [
  {
    label: "Inactive",
    value: 0,
  },
  {
    label: "Active",
    value: 1,
  },
];

export {
  plans_types,
  plan_level_types,
  monthly_plans,
  plans_types_enums,
  plan_status,
  plan_category_enums,
  plan_level_enums
};
