import React from "react";
import styles from "../style.module.scss";
import classNames from "classnames";
import { plans_types, plans_types_enums } from "../constant";

const PlanTypeSelection = ({ selectedItem, formik, setIsPromotional }: any) => {
  const { values } = formik;

  return (
    <div className={styles.radioContainer}>
      {selectedItem
        ? plans_types
            ?.filter((itm: any) => itm?.value === Number(selectedItem?.type))
            ?.map((item: any) => {
              return (
                <>
                  <div>
                    <div className={styles.singleRadio}>
                      <div
                        className={classNames(
                          styles.radioBorderSelected,
                          styles.radioBorder
                        )}
                      >
                        <div className={styles.selected} />
                      </div>
                      <label
                        className={classNames(
                          styles.radioLabel,
                          styles.radioSelectedLabel
                        )}
                      >
                        {item?.label}
                      </label>
                    </div>
                  </div>
                </>
              );
            })
        : plans_types?.map((item: any) => {
            return (
              <>
                <div>
                  <div className={styles.singleRadio}>
                    <div
                      onClick={() => {
                        if (item?.value === plans_types_enums.promotional) {
                          setIsPromotional(true);
                        } else {
                          setIsPromotional(false);
                        }
                        formik.setFieldValue("plan_type", item);
                        formik.setFieldValue("start_date", null);
                        formik.setFieldValue("end_date", null);
                      }}
                      className={
                        values?.plan_type?.value === item?.value
                          ? classNames(
                              styles.radioBorderSelected,
                              styles.radioBorder
                            )
                          : styles.radioBorder
                      }
                    >
                      {values?.plan_type?.value === item?.value && (
                        <div className={styles.selected} />
                      )}
                    </div>
                    <label
                      className={
                        values?.plan_type?.value === item?.value
                          ? classNames(
                              styles.radioLabel,
                              styles.radioSelectedLabel
                            )
                          : classNames(styles.radioLabel)
                      }
                    >
                      {item?.label}
                    </label>
                  </div>
                </div>
              </>
            );
          })}
    </div>
  );
};

export default PlanTypeSelection;
