import { Grid } from "@mui/material";
import { Icons } from "assets";
import styles from "pages/manageBanner/style.module.scss";
import React, { useRef, useState } from "react";

interface UploadBannerSectionProps {
  setFieldValue: (field: string, value: any) => void;
  errors: Record<string, any>;
  clickableChecked: boolean;
  touched: Record<string, any>;
  values: any;
  setIsButtonImageAdded: (val: boolean) => void;
  setExternalLinkAdded: any;
}

function UploadBannerSection({
  setFieldValue,
  errors,
  clickableChecked,
  touched,
  values,
  setIsButtonImageAdded,
  setExternalLinkAdded,
}: UploadBannerSectionProps) {
  const [banner, setBanner] = useState<any>({
    raw: "",
    preview: values?.banner_image ? values?.banner_image : "",
  });
  const [buttonImg, setButtonImage] = useState<any>({
    raw: "",
    preview: values?.button_image ? values?.button_image : "",
  });

  const bannerRef = useRef<HTMLInputElement | null>(null);
  const buttonImgRef = useRef<HTMLInputElement | null>(null);

  const handleImageChange = (e: any, banner: boolean) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        if (banner) {
          setBanner({
            preview: e.target.result as string,
            raw: file,
          });
          setFieldValue("banner_image", file);
        } else {
          setButtonImage({
            preview: e.target.result as string,
            raw: file,
          });
          setFieldValue("button_image", file);
          setIsButtonImageAdded(true);
        }
      };

      reader.readAsDataURL(file);
    }
  };

  const handleImageDelete = (banner: boolean) => {
    if (banner) {
      setBanner({ preview: "", raw: null });
      setFieldValue("banner_image", "");
      if (bannerRef.current) {
        bannerRef.current.value = "";
      }
    } else {
      setButtonImage({ preview: "", raw: null });
      if (buttonImgRef.current) {
        buttonImgRef.current.value = "";
      }
      setFieldValue("button_image", "");
      setIsButtonImageAdded(false);
    }
  };

  const handleButtonClick = (banner: boolean) => {
    if (banner) {
      if (bannerRef.current) {
        bannerRef.current.click();
      }
    } else {
      if (buttonImgRef.current) {
        buttonImgRef.current.click();
      }
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8} lg={9}>
          <div className={styles.section}>
            <label className={styles.label}>Upload Banner Image</label>
            <div>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleImageChange(e, true)}
                ref={bannerRef}
                style={{ display: "none" }}
              />
              <div className={styles.imgContainer}>
                {banner?.preview && (
                  <Icons.DeleteIcon
                    className={styles.deleteIcon}
                    onClick={() => handleImageDelete(true)}
                  />
                )}
                {banner.preview ? (
                  <img
                    src={banner.preview && banner.preview}
                    alt={"user"}
                    className={styles.uploadedPicture}
                    onClick={() => handleButtonClick(true)}
                  />
                ) : (
                  <div
                    className={styles.uploadContainer}
                    onClick={() => handleButtonClick(true)}
                  >
                    <Icons.UploadFileIcon />
                    <label>Please Upload banner</label>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.error}>
              {touched?.banner_image && errors?.banner_image
                ? errors?.banner_image.toString()
                : null}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <div className={styles.section}>
            <label className={styles.label}>Upload Button Image here</label>
            <div>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleImageChange(e, false)}
                ref={buttonImgRef}
                style={{ display: "none" }}
              />
              <div className={styles.imgContainer}>
                {buttonImg?.preview && (
                  <Icons.DeleteIcon
                    className={styles.deleteIcon}
                    onClick={() => handleImageDelete(false)}
                  />
                )}
                {buttonImg.preview ? (
                  <img
                    src={buttonImg.preview && buttonImg.preview}
                    alt={"user"}
                    className={styles.uploadedPicture}
                    onClick={() => handleButtonClick(false)}
                  />
                ) : (
                  <div
                    className={styles.uploadContainer}
                    onClick={() => handleButtonClick(false)}
                  >
                    <Icons.UploadFileIcon />
                    <label>Please Upload button image here</label>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.error}>
              {touched?.button_image && errors?.button_image
                ? errors?.button_image.toString()
                : null}
            </div>
          </div>
        </Grid>
      </Grid>
      <div style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
        <label className={styles.label}>
          Add {!clickableChecked ? "External" : "Internal"} URL
        </label>
        <label className={styles.subLabel}>
          The user will navigate to the given{" "}
          {clickableChecked
            ? "internal link /slug e.g. story_competition, "
            : "external link "}{" "}
          which you will add below (this field is compulsory after adding button
          image)
        </label>
        <input
          className={styles.urlInput}
          placeholder={
            !clickableChecked ? `https://google.com` : `Add internal/slug link`
          }
          value={clickableChecked ? values?.internal_url : values?.external_url}
          onChange={(e) => {
            if (clickableChecked) {
              setFieldValue("internal_url", e.target.value);
            } else {
              setFieldValue("external_url", e.target.value);
              setExternalLinkAdded(
                String(e.target.value)?.length > 0 ? true : false
              );
            }
          }}
        />
        <div className={styles.error}>
          {clickableChecked
            ? touched?.internal_url && errors?.internal_url
              ? errors?.internal_url.toString()
              : null
            : touched?.external_url && errors?.external_url
            ? errors?.external_url.toString()
            : null}
        </div>
      </div>
    </div>
  );
}

export default UploadBannerSection;
