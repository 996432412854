import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Icons } from "assets";
import * as React from "react";
import styles from "./style.module.scss";
import { Grid } from "@mui/material";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface ViewBannerProps {
  open: boolean;
  handleClose: () => void;
  item: any;
}

export default function ViewBanner({
  open,
  handleClose,
  item,
}: ViewBannerProps) {
  const onClose = () => {
    handleClose();
  };
  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          sx: {
            padding: "24px",
            width: "100%",
            justifyContent: "center",
            maxWidth: (theme) => theme.spacing(90),
            borderRadius: "10px",
          },
        }}
      >
        <DialogContent
          sx={{ padding: "0px" }}
          className={styles.dialogContainer}
        >
          <div>
            <Icons.CrossIcon
              className={styles.crossIcon}
              onClick={() => handleClose()}
            />
            <label className={styles.heading}>View Announcement Detail</label>
          </div>
          <Grid container spacing={2} marginTop={"1rem"}>
            <Grid item xs={6}>
              <div className={styles.detailContainer}>
                <div className={styles.section}>
                  <label className={styles.sectionHeading}>Image Title</label>
                  <div
                    dangerouslySetInnerHTML={{ __html: item?.title }}
                    className={styles.labelDescription}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={styles.detailContainer}>
                <div className={styles.section}>
                  <label className={styles.sectionHeading}>User Type</label>
                  <label className={styles.labelDescription}>
                    {item?.user_type === 0 ? "Public Users" : "Logged Users"}
                  </label>
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={styles.detailContainer}>
                <div className={styles.section}>
                  <label className={styles.sectionHeading}>Clickable:</label>
                  <label className={styles.labelDescription}>
                    {item?.is_clickable === 0 ? "No" : "Yes"}
                  </label>
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={styles.detailContainer}>
                <div className={styles.section}>
                  <label className={styles.sectionHeading}>
                    External Link:
                  </label>
                  <label className={styles.labelDescription}>
                    {item?.external_url}
                  </label>
                </div>
              </div>
            </Grid>
            {item?.internal_url ? (
              <Grid item xs={6}>
                <div className={styles.detailContainer}>
                  <div className={styles.section}>
                    <label className={styles.sectionHeading}>
                      Internal Link:
                    </label>
                    <label className={styles.labelDescription}>
                      {item?.internal_url}
                    </label>
                  </div>
                </div>
              </Grid>
            ) : null}
            {item?.button_text ? (
              <Grid item xs={6}>
                <div className={styles.detailContainer}>
                  <div className={styles.section}>
                    <label className={styles.sectionHeading}>Button Text</label>
                    <label className={styles.labelDescription}>
                      {item?.button_text}
                    </label>
                  </div>
                </div>
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <div className={styles.detailContainer}>
                <div className={styles.section}>
                  <label className={styles.sectionHeading}>Banner Image</label>
                  <img
                    src={item?.banner_image}
                    alt="banner"
                    className={styles.bannerImg}
                  />
                </div>
              </div>
            </Grid>
            {item?.button_image ? (
              <Grid item xs={12}>
                <div className={styles.detailContainer}>
                  <div className={styles.section}>
                    <label className={styles.sectionHeading}>
                      Button Image:
                    </label>
                    <img
                      src={item?.button_image}
                      alt="banner"
                      className={styles.buttonImage}
                    />
                  </div>
                </div>
              </Grid>
            ) : null}

            {item?.description ? (
              <Grid item xs={12}>
                <div className={styles.detailContainer}>
                  <div className={styles.section}>
                    <label className={styles.sectionHeading}>Description</label>
                    <div
                      dangerouslySetInnerHTML={{ __html: item?.description }}
                      className={styles.labelDescription}
                    />
                  </div>
                </div>
              </Grid>
            ) : null}
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
