import React, { useEffect, useRef, useState } from "react";
import styles from "../style.module.scss";
import { Grid } from "@mui/material";
import { Icons } from "assets";

function CardsSection({ formik, selectedItem }: any) {
  const { values, errors } = formik;
  const WebCardImageRef = useRef<HTMLInputElement | null>(null);
  const MobileCardImageRef = useRef<HTMLInputElement | null>(null);
  const [webCardImage, setWebCardImage] = useState<any>({
    raw: "",
    preview: "",
  });
  const [mobileCardImage, setMobileCardImage] = useState<any>({
    raw: "",
    preview: "",
  });

  const handleImage = (type: string) => {
    if (type === "Web") {
      if (WebCardImageRef.current) {
        WebCardImageRef.current.click();
      }
    } else {
      if (MobileCardImageRef.current) {
        MobileCardImageRef.current.click();
      }
    }
  };

  const handleImageChange = (e: any, type: string) => {
    let url = URL.createObjectURL(e.target.files[0]);
    const file = e.target.files && e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        if (type === "Web") {
          setWebCardImage({
            preview: e.target.result as string,
            raw: file,
          });
          formik.setFieldValue("web_card", file);
        } else {
          setMobileCardImage({
            preview: e.target.result as string,
            raw: file,
          });
          formik.setFieldValue("mobile_card", file);
        }
      };

      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (selectedItem) {
      if (selectedItem?.mobile_card) {
        setMobileCardImage({
          preview: selectedItem?.mobile_card,
          raw: null,
        });
      }
      if (selectedItem?.web_card) {
        setWebCardImage({
          preview: selectedItem?.web_card,
          raw: null,
        });
      }
    }
  }, [selectedItem]);

  return (
    <Grid container className={styles.cardsSection}>
      <Grid xs={12} md={6} paddingRight={"8px"}>
        <div className={styles.inputLabel}>Web (Preview)</div>
        <div className={styles.cardDescription}>
          Card should adheres to the specified dimensions of 1280x375
        </div>
        <input
          type="file"
          accept="image/*"
          onChange={(e) => handleImageChange(e, "Web")}
          ref={WebCardImageRef}
          style={{ display: "none" }}
        />
        <div
          className={styles.uploadContainer}
          onClick={() => handleImage("Web")}
        >
          {webCardImage?.preview && (
            <Icons.DeleteIcon
              className={styles.deleteIcon}
              onClick={() => {
                setWebCardImage({
                  raw: "",
                  preview: "",
                });
                formik.setFieldValue("web_card", "");
              }}
            />
          )}
          {webCardImage?.preview ? (
            <img
              src={webCardImage?.preview}
              alt="Upload"
              className={styles.uploadedImageContainer}
            />
          ) : (
            <>
              <Icons.UploadFileIcon />
              <label>Click to upload Web Card</label>
            </>
          )}
        </div>
        <div style={{ marginTop: "12px" }}>
          {formik.touched.web_card && formik.errors.web_card && (
            <div className={styles.errorText}>{formik.errors.web_card}</div>
          )}
        </div>
      </Grid>
      <Grid xs={12} md={6} paddingLeft={"8px"}>
        <div className={styles.inputLabel}>Mobile (Preview)</div>
        <div className={styles.cardDescription}>
          Card should adheres to the specified dimensions of 380x194
        </div>
        <input
          type="file"
          accept="image/*"
          onChange={(e) => handleImageChange(e, "Mobile")}
          ref={MobileCardImageRef}
          style={{ display: "none" }}
        />
        <div
          className={styles.uploadContainer}
          onClick={() => handleImage("Mobile")}
        >
          {mobileCardImage?.preview && (
            <Icons.DeleteIcon
              className={styles.deleteIcon}
              onClick={() => {
                setMobileCardImage({
                  raw: "",
                  preview: "",
                });
                formik.setFieldValue("mobile_card", "");
              }}
            />
          )}
          {mobileCardImage?.preview ? (
            <img
              src={mobileCardImage?.preview}
              alt="Upload"
              className={styles.uploadedImageContainer}
            />
          ) : (
            <>
              <Icons.UploadFileIcon />
              <label>Click to upload Mobile Card</label>
            </>
          )}
        </div>
        <div style={{ marginTop: "12px" }}>
          {formik.touched.mobile_card && formik.errors.mobile_card && (
            <div className={styles.errorText}>{formik.errors.mobile_card}</div>
          )}
        </div>
        {/* {formik.touched.mobileCard && formik.errors.mobileCard ? (
          <div className={styles.errorText}>{formik.errors.mobileCard}</div>
        ) : (
          <div style={{ height: "20px" }}></div>
        )} */}
      </Grid>
    </Grid>
  );
}

export default CardsSection;
