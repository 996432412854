import React, { useState } from "react";
import styles from "pages/manageBanner/style.module.scss";
import ReactQuill from "react-quill";

interface ImageTitleSectionProps {
  setFieldValue: (field: string, value: any) => void;
  errors: Record<string, any>;
  touched: Record<string, any>;
  values: any;
}

function ImageTitleSection({
  setFieldValue,
  errors,
  touched,
  values,
}: ImageTitleSectionProps) {
  const [textContent, setTextContent] = useState<string>("");

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }],
      [{ size: [] }],
      [
        {
          color: [
            "#9a469b",
            "#ef437b",
            "#0d2a52",
            "#1897a6",
            "#027a48",
            "#b42318",
            "color-picker",
          ],
        },
      ],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "image",
    "link",
    "color",
  ];

  function handleTextChange(value: any) {
    setTextContent(value);
    if (hasContent(value)) {
      setFieldValue("title", value);
    } else {
      setFieldValue("title", "");
    }
  }

  function hasContent(htmlString: string) {
    let strippedString = htmlString.replace(/<[^>]*>/g, "");

    strippedString = strippedString.trim();

    return strippedString ? true : false;
  }

  return (
    <div className={styles.section}>
      <label className={styles.label}>Add Image Title *</label>
      <div className={styles.textEditorContainer}>
        <ReactQuill
          value={values?.title}
          onChange={handleTextChange}
          modules={modules}
          formats={formats}
        />
      </div>
      <div className={styles.error}>
        {touched?.user_type && errors?.title ? errors?.title.toString() : null}
      </div>
    </div>
  );
}

export default ImageTitleSection;
