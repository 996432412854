import React, { useEffect, useState } from "react";
import AutoCompleteUsers from "shared/components/autoCompleteUsers";
import { toastMessage } from "shared/components/toast";
import { getAllSchoolListService } from "shared/services/productManagerService";
import styles from "../style.module.scss";
import { Icons } from "assets";

function SchoolSelection({ formik, selectedItem }: any) {
  const { touched, errors } = formik;
  const [schoolList, setSchoolList] = useState<any>([]);
  const [selectedSchool, setSelectedSchool] = useState<any>(null);
  const [searchSchool, setSearchSchool] = useState<string>("");

  const getAllSchoolsList = () => {
    getAllSchoolListService({ search: searchSchool })
      .then(({ status, data }) => {
        if (status) {
          setSchoolList(data?.data?.data);
        }
      })
      .catch((err: any) => {
        toastMessage("error", err?.response?.data?.message);
      });
  };

  useEffect(() => {
    getAllSchoolsList();
    //eslint-disable-next-line
  }, [searchSchool]);

  console.log("selectedItem", selectedItem);

  return (
    <div style={{ marginTop: "5px", marginBottom: "10px" }}>
      {selectedItem?.school_name ? (
        <div className={styles.selectedSchool}>
          <Icons.RoleIconStudents className={styles.classRoomIcon} />
          {selectedItem?.school_name}
        </div>
      ) : (
        <>
          <div className={styles.inputLabel}>Select School</div>
          <AutoCompleteUsers
            user={selectedSchool ? selectedSchool : null}
            userList={schoolList ? schoolList : []}
            setSelectedUser={(school: any) => {
              formik.setFieldValue("school", school);
              setSelectedSchool(school);
            }}
            selectedUser={selectedSchool}
            searchText={searchSchool}
            setSearchText={setSearchSchool}
          />
          <div style={{ marginTop: "12px" }}>
            {touched?.school && errors?.school && (
              <span className={styles.errorText}>{String(errors?.school)}</span>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default SchoolSelection;
