import { Grid } from "@mui/material";
import styles from "pages/manageBanner/style.module.scss";
import { useState } from "react";
import ReactQuill from "react-quill";
import { toastMessage } from "shared/components/toast";
import { createPhotoLinkService } from "shared/services/productManagerService";
import innerStyles from "./style.module.scss";

interface AddBannerDetailInfoProps {
  setFieldValue: (field: string, value: any) => void;
  errors: Record<string, any>;
  touched: Record<string, any>;
  values: any;
  setEditorLoader: any;
}

function AddBannerDetailInfo({
  setFieldValue,
  errors,
  touched,
  values,
  setEditorLoader,
}: AddBannerDetailInfoProps) {
  const [uploadingPhotoLoader, setUploadPhotoLoader] = useState<boolean>(false);

  const modules = {
    toolbar: {
      container: [
        [{ header: "1" }, { header: "2" }],
        [{ size: [] }],
        [
          {
            color: [
              "#9a469b",
              "#ef437b",
              "#0d2a52",
              "#1897a6",
              "#027a48",
              "#b42318",
              "color-picker",
            ],
          },
        ],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["clean"],
        ["link", "image"],
      ],
    },
  };

  const formats = [
    "header",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "image",
    "link",
    "color",
  ];

  function handleTextChange(value: any) {
    detectBase64Images(value);

    if (hasContent(value)) {
      setFieldValue("description", value);
    } else {
      setFieldValue("description", "");
    }
  }

  function hasContent(htmlString: string) {
    let strippedString = htmlString.replace(/<[^>]*>/g, "");

    strippedString = strippedString.trim();

    return strippedString ? true : false;
  }

  //   function extractImages(content: string) {
  //     const images = [];
  //     const regex = /<img\s+src="(data:image\/[a-zA-Z]+;base64,[^"]+)"/g;
  //     let match;
  //     while ((match = regex.exec(content)) !== null) {
  //       images.push(match[1]);
  //     }

  //     console.log(images);
  //   }

  function uploadImage(base64Image: any, file: File, content: string) {
    setUploadPhotoLoader(true);
    setEditorLoader(true);
    createPhotoLinkService(file)
      .then(({ data: { data } }) => {
        console.log("data");
        replaceBase64WithUrl(base64Image, data, content);
        setUploadPhotoLoader(false);
        setEditorLoader(false);
      })
      .catch((err) => {
        console.log("err", err);
        toastMessage("error", err?.response?.data?.message);
        setUploadPhotoLoader(false);
        setEditorLoader(false);
      });
  }

  async function detectBase64Images(content: string) {
    const regex = /<img\s+src="(data:image\/[a-zA-Z]+;base64,[^"]+)"/g;
    let match;
    while ((match = regex.exec(content)) !== null) {
      const base64Image: any = match[1];
      uploadImage(base64Image, base64ToFile(base64Image, "image"), content);
    }
  }

  function base64ToFile(base64String: any, filename: string) {
    const arr = base64String.split(",");
    const mimeMatch = arr[0].match(/:(.*?);/);
    if (!mimeMatch) {
      throw new Error("Invalid base64 string");
    }
    const mime = mimeMatch[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  function replaceBase64WithUrl(base64: string, url: string, content: string) {
    setFieldValue("description", content?.replace(base64, url));
  }

  return (
    <div className={innerStyles.mainContainer}>
      <label className={styles.label} style={{ fontSize: "18px" }}>
        Add Detail page info
      </label>
      <div className={styles.section}>
        <label className={styles.label}>Add Detail</label>
        <div className={innerStyles.textEditorContainer}>
          <ReactQuill
            value={values?.description}
            onChange={handleTextChange}
            modules={modules}
            formats={formats}
            readOnly={uploadingPhotoLoader}
          />
        </div>
        <div className={styles.error}>
          {touched?.description && errors?.description
            ? errors?.description.toString()
            : null}
        </div>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <div className={styles.section}>
            <label className={styles.label}>Add Detail Page button text</label>
            <input
              className={innerStyles.input}
              placeholder="Add Button text e.g View More details, know more..."
              value={values?.button_text}
              onChange={(e) => setFieldValue("button_text", e.target.value)}
            />
            <div className={styles.error}>
              {touched?.button_text && errors?.button_text
                ? errors?.button_text.toString()
                : null}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={styles.section}>
            <label className={styles.label}>
              Add Detail Page button URL (External URL)
            </label>
            <input
              className={innerStyles.input}
              placeholder="https://google.com/"
              value={values?.external_url}
              onChange={(e) => setFieldValue("external_url", e.target.value)}
            />
            <div className={styles.error}>
              {touched?.external_url && errors?.external_url
                ? errors?.external_url.toString()
                : null}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default AddBannerDetailInfo;
