import { Endpoint } from "shared/utils/endpoints";
import { HTTP_CLIENT } from "../utils/interceptor";
import moment from "moment";

const getAllPlansService = (
  resp: any,
  activePage: any,
  activePlanTypeTab: any
) => {
  const params = new URLSearchParams();
  if (resp?.category) {
    params.append("category", resp?.category.toString());
  }
  if (activePlanTypeTab) {
    params.append("level", activePlanTypeTab.toString());
  }
  if (activePage) params.append("page", activePage.toString());

  return HTTP_CLIENT.get(
    `${Endpoint.product_manager.getAllPlans}?${params.toString()}`
  );
};

const getAllSchoolListService = ({ search }: any) => {
  const params = new URLSearchParams();
  if (search) params.append("search", search);
  const url = `${Endpoint.product_manager.getAllSchools}?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const createPlanService = (resp: {}) => {
  return HTTP_CLIENT.post(Endpoint.product_manager.createPlan, resp);
};

const updatePlanService = (resp: {}) => {
  return HTTP_CLIENT.post(Endpoint.product_manager.updatePlan, resp);
};

const deletePlanService = (id: any) => {
  return HTTP_CLIENT.post(Endpoint.product_manager.deletePlan + id.toString());
};

const activatePlanService = (id: any) => {
  return HTTP_CLIENT.post(
    Endpoint.product_manager.activatePlan + id.toString()
  );
};

const inActivatePlanService = (id: any) => {
  return HTTP_CLIENT.post(
    Endpoint.product_manager.inactivatePlan + id.toString()
  );
};

const getAllBalanceService = () => {
  return HTTP_CLIENT.get(Endpoint.product_manager.getAllBalance);
};

const addBalanceService = (resp: {}) => {
  return HTTP_CLIENT.post(Endpoint.product_manager.addBalance, resp);
};

const updateBalanceService = (resp: {}) => {
  return HTTP_CLIENT.post(Endpoint.product_manager.updateBalance, resp);
};

const deleteBalanceService = (id: any) => {
  return HTTP_CLIENT.post(
    Endpoint.product_manager.deleteBalance + id.toString()
  );
};

const getFeaturedBooksService = (
  status: any,
  search: any,
  activePage: any,
  itemsPerPage: number
) => {
  const params = new URLSearchParams();
  if (search) params.append("search", search);
  if (status === 0 || status) params.append("status", status.toString());
  if (activePage) params.append("page", activePage.toString());
  if (itemsPerPage) params.append("per_page", itemsPerPage.toString());
  const url = `${
    Endpoint.product_manager.allFeaturedBooks
  }?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const approveFeatureBookService = (id: any) => {
  return HTTP_CLIENT.post(
    Endpoint.product_manager.approveFeatureBook + id.toString()
  );
};

const rejectBookService = (resp: {}) => {
  return HTTP_CLIENT.post(Endpoint.product_manager.rejectFeatureBook, resp);
};

const uploadFeatureCoverService = (resp: {}) => {
  return HTTP_CLIENT.post(Endpoint.product_manager.uploadFeatureCover, resp);
};

const getAllPublishersService = (search: any) => {
  const params = new URLSearchParams();
  if (search) params.append("search", search);
  const url = `${
    Endpoint.product_manager.getAllPublishers
  }?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const getAllPublisherBooks = (id: any, search: string) => {
  const params = new URLSearchParams();
  if (search) params.append("search", search);
  return HTTP_CLIENT.get(
    Endpoint.product_manager.getPublisherBooks + `${id}/books/?${params}`
  );
};

const addBookFeatureService = (resp: {}) => {
  return HTTP_CLIENT.post(Endpoint.product_manager.addBook, resp);
};

const activateFeatureBookService = (id: any) => {
  return HTTP_CLIENT.post(
    Endpoint.product_manager.activateBook + id.toString()
  );
};

const inActivateFeatureBookService = (id: any) => {
  return HTTP_CLIENT.post(
    Endpoint.product_manager.deactivateBook + id.toString()
  );
};

const getBookHistoryService = (id: any) => {
  return HTTP_CLIENT.get(Endpoint.product_manager.bookHistory + id.toString());
};

const allReportedBooksLists = (
  searchText: any,
  activeTab: any,
  itemsPerPage: number
) => {
  const params = new URLSearchParams();
  if (searchText) params.append("search", searchText);
  if (activeTab) params.append("page", activeTab.toString());
  if (itemsPerPage) params.append("per_page", itemsPerPage.toString());
  const url = `${
    Endpoint.product_manager.reportedBooksLists
  }?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const pendingBooksLists = (id: number, search: any, itemsPerPage: number) => {
  const params = new URLSearchParams();
  // if (search) params.append("search", search);

  if (itemsPerPage) params.append("per_page", itemsPerPage.toString());
  const url = `${
    Endpoint.product_manager.pendingBooksLists + id
  }?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const historyBookLists = (id: number, search: any, itemsPerPage: number) => {
  const params = new URLSearchParams();
  if (search) params.append("search", search);
  if (itemsPerPage) params.append("per_page", itemsPerPage.toString());
  const url = `${
    Endpoint.product_manager.historyBookLists + id
  }?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const approvedBookService = (id: number) => {
  return HTTP_CLIENT.post(Endpoint.product_manager.approveBook + id);
};

const declineBookService = (id: number) => {
  return HTTP_CLIENT.post(Endpoint.product_manager.declineBook + id);
};

const allGenreService = (
  search?: string,
  activePage?: number | null,
  status?: any,
  itemsPerPage?: number
) => {
  const params = new URLSearchParams();
  if (search) params.append("search", search);
  if (status) params.append("status", status);
  if (activePage) params.append("page", activePage.toString());
  if (itemsPerPage) params.append("per_page", itemsPerPage.toString());
  const url = `${Endpoint.product_manager.allGenres}?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const createGenreService = (resp: {}) => {
  return HTTP_CLIENT.post(Endpoint.product_manager.createGenre, resp);
};

const updateGenresService = (resp: {}) => {
  return HTTP_CLIENT.post(Endpoint.product_manager.updateGenres, resp);
};

const deleteGenresService = (id: number) => {
  return HTTP_CLIENT.post(Endpoint.product_manager.deleteGenres + id);
};

const activateGenreService = (id: number) => {
  return HTTP_CLIENT.post(Endpoint.product_manager.activateGenre + id);
};

const inActivateGenreService = (id: number) => {
  return HTTP_CLIENT.post(Endpoint.product_manager.inactiveGenre + id);
};

const ticketUserService = (
  search: any,
  activePage: any,
  itemsPerPage: number
) => {
  const params = new URLSearchParams();
  if (search) params.append("search", search);
  if (activePage) params.append("page", activePage.toString());
  if (itemsPerPage) params.append("per_page", itemsPerPage.toString());
  const url = `${Endpoint.product_manager.ticketUsers}?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const createTicketByProductManager = (resp: {}) => {
  return HTTP_CLIENT.post(Endpoint.product_manager.createTicket, resp);
};

const createTicketAndServiceByProductManager = (resp: {}) => {
  return HTTP_CLIENT.post(
    Endpoint.product_manager.createTicketAndCommentByProductManager,
    resp
  );
};

const addMessageByProductManager = (resp: {}) => {
  return HTTP_CLIENT.post(Endpoint.product_manager.addComment, resp);
};

const closeTicketByProductManager = (id: any) => {
  return HTTP_CLIENT.post(Endpoint.product_manager.closeTicket + id);
};

const getUserTicketsManager = (id: any) => {
  return HTTP_CLIENT.get(Endpoint.product_manager.userTickets + id);
};

const getThreadProductManager = (id: any) => {
  return HTTP_CLIENT.get(Endpoint.product_manager.getAllComments + id);
};

const getAllUsersProductManager = () => {
  return HTTP_CLIENT.get(Endpoint.product_manager.getAllUserList);
};

const getCatalogStats = () => {
  return HTTP_CLIENT.get(Endpoint.product_manager.catalogStats);
};

const getGenreStats = () => {
  return HTTP_CLIENT.get(Endpoint.product_manager.genreStats);
};

const getBooksStats = (sort_by: any) => {
  const params = new URLSearchParams();
  if (sort_by) params.append("sort_by", sort_by);
  const url = `${Endpoint.product_manager.booksStats}?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const getSoftCopyStats = (dates: any) => {
  const params = new URLSearchParams();
  if (dates?.startDate)
    params.append("from", moment(dates?.startDate).format("YYYY-MM-DD"));
  if (dates?.endDate)
    params.append("to", moment(dates?.endDate).format("YYYY-MM-DD"));
  const url = `${Endpoint.product_manager.softCopySales}?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const getHardCopyStats = (dates: any) => {
  const params = new URLSearchParams();
  if (dates?.startDate)
    params.append("from", moment(dates?.startDate).format("YYYY-MM-DD"));
  if (dates?.endDate)
    params.append("to", moment(dates?.endDate).format("YYYY-MM-DD"));
  const url = `${Endpoint.product_manager.hardCopySales}?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const getTopSalesHardCopy = () => {
  return HTTP_CLIENT.get(Endpoint.product_manager.topSalesHardCopy);
};

const getTopSalesSoftCopy = (activePage: any) => {
  const params = new URLSearchParams();

  if (activePage) params.append("page", activePage.toString());
  const url = `${
    Endpoint.product_manager.topSalesSoftCopy
  }?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const deleteBookService = (id: any) => {
  return HTTP_CLIENT.post(Endpoint.product_manager.deleteBook + id);
};

const getRevisionHistoryByProductManager = (id: any) => {
  return HTTP_CLIENT.get(Endpoint.product_manager.revisionHistory + id);
};

const getAllGraphsData = (dates: any, selectedSaleType: string) => {
  const params = new URLSearchParams();
  if (dates?.startDate && dates?.endDate) {
    params.append("from", moment(dates?.startDate).format("YYYY-MM-DD"));
    params.append("to", moment(dates?.endDate).format("YYYY-MM-DD"));
  }

  if (selectedSaleType && selectedSaleType.length) {
    params.append("sales_type", selectedSaleType);
  }

  const url = `${Endpoint.product_manager.allGraphs}?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const getSalesBooks = (
  activePage: any,
  languages: any,
  ageRange: any,
  genres: any,
  sales_type?: any
) => {
  let resp: any = {
    sales_type: sales_type,
    genres: genres,
    age_range_id: ageRange,
    languages: languages,
    page: activePage,
  };

  if (ageRange?.length > 0) {
    resp["age_range_id"] = ageRange;
  }

  if (sales_type?.length > 0) {
    resp["sales_type"] = sales_type;
  }

  let formBody: any = new FormData();
  Object.keys(resp).forEach((key) => {
    if (Array.isArray(resp[key])) {
      resp[key].forEach((value: any, index: any) => {
        formBody.append(`${key}[]`, value);
      });
    } else {
      formBody.append(key, resp[key]);
    }
  });

  return HTTP_CLIENT.post(Endpoint.product_manager.salesBooks, formBody);

  // const url = `${Endpoint.product_manager.salesBooks}?${params.toString()}`;
  // return HTTP_CLIENT.post(url);
};
const getTransactionRequestList = (
  search: any,
  activePage: number,
  itemsPerPage: number
) => {
  const params = new URLSearchParams();
  if (search) {
    params.append("search", search);
  }
  if (activePage) {
    params.append("page", activePage.toString());
  }
  if (itemsPerPage) {
    params.append("per_page", itemsPerPage.toString());
  }
  return HTTP_CLIENT.get(
    `${Endpoint.product_manager.transactionRequests}?${params.toString()}`
  );
};

const getProductManagerSubscriptionsList = (
  userId: any,
  search: string,
  status: number,
  activePage: number,
  itemsPerPage: number
) => {
  const params = new URLSearchParams();

  if (search) {
    params.append("search", search);
  }
  if (userId) {
    params.append("user_id", userId.toString());
  }
  if (status) {
    params.append("status", status.toString());
  }
  if (activePage) {
    params.append("page", activePage.toString());
  }
  if (itemsPerPage) {
    params.append("per_page", itemsPerPage.toString());
  }
  return HTTP_CLIENT.get(
    `${Endpoint.product_manager.subscriptionRequests}?${params.toString()}`
  );
};

// GET USER TRANSACTIONS LIST
const getUserTransactionsList = (userId: number) => {
  const params = new URLSearchParams();
  if (userId) params.append("subscribe_id", userId.toString());
  return HTTP_CLIENT.get(
    `${Endpoint.product_manager.userTransactionsList}?${params.toString()}`
  );
};

const rejectTrasactionByProductManager = (tId: number, reason = "") => {
  const params = new URLSearchParams();
  if (tId) {
    params.append("id", tId.toString());
  }
  if (reason) {
    params.append("reason", reason);
  }

  return HTTP_CLIENT.post(
    `${Endpoint.product_manager.rejectTransactionRequest}?${params.toString()}`
  );
};

const approveTransactionByProductManager = (tId: number) => {
  return HTTP_CLIENT.post(
    `${Endpoint.product_manager.approveTransactionRequest}/${tId.toString()}`
  );
};

// TERMS AND CONDITIONS API AND PRIVACY POLICY

const getPolicyDataAPI = (type: any) => {
  return HTTP_CLIENT.get(
    `${Endpoint.product_manager.getPolicyDataAPI}/${type.toString()}`
  );
};

const updatePolicyDataAPI = (data: any) => {
  // const { description, title, type } = data;
  // const params = new URLSearchParams();
  // if (description) {
  //   params.append("content", description);
  // }
  // if (type) {
  //   params.append("page_type", type.toString());
  // }
  // if (title) {
  //   params.append("title", title);
  // }
  return HTTP_CLIENT.post(
    Endpoint.product_manager.updateGetPolicyDataAPI,
    data
  );
};

// News Letter List
const getNewsletterListProductManager = (
  search: string = "",
  activePage: number,
  per_page: number | null = null
) => {
  const params = new URLSearchParams();
  if (activePage) params.append("page", activePage.toString());
  if (search) params.append("search", search);
  if (per_page) params.append("per_page", per_page.toString());

  return HTTP_CLIENT.get(
    `${Endpoint.product_manager.getNewsletterListProductManager}?${params}`
  );
};

// delete newsletter
const deleteNewsLetterProductManager = (newsLetterId: any) => {
  return HTTP_CLIENT.post(
    `${Endpoint.product_manager.deleteNewsletterProductManager}${newsLetterId}`
  );
};

const transactionHistoryList = (search: any, status: any, currentPage: any) => {
  console.log("status", status);
  const params = new URLSearchParams();
  if (search) params.append("search", search);
  if (status || status === 0) params.append("status", status.toString());
  if (currentPage) params.append("page", currentPage.toString());

  return HTTP_CLIENT.get(
    `${Endpoint.product_manager.transactionHistoryList}?${params}`
  );
};

const checkTransactionInquiry = (id: any) => {
  return HTTP_CLIENT.post(
    Endpoint.product_manager.transactionInquiry.replace(":id", id)
  );
};

const getBannerListings = () => {
  return HTTP_CLIENT.get(Endpoint.product_manager.bannerListings);
};

const addBanner = (resp: any) => {
  return HTTP_CLIENT.post(Endpoint.product_manager.addBanner, resp);
};

const deleteBanner = (id: any) => {
  return HTTP_CLIENT.post(
    Endpoint.product_manager.deleteBanner.replace(":id", id)
  );
};

const individualAccountService = (
  search?: any,
  activePage?: any,
  ageRangeId?: any,
  userAccountId?: any,
  startDate?: any,
  endDate?: any
) => {
  const params = new URLSearchParams();
  if (search) params.append("search", search);
  if (activePage) params.append("page", activePage.toString());
  if (ageRangeId) params.append("age_range_id", ageRangeId.toString());
  if (userAccountId)
    params.append("account_subscription_type", userAccountId.toString());
  if (startDate)
    params.append("from_date", moment(startDate).format("YYYY-MM-DD"));
  if (endDate) params.append("to_date", moment(endDate).format("YYYY-MM-DD"));

  const url = `${
    Endpoint.product_manager.individualAccounts
  }?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const parentAccountsService = (
  search: any,
  activePage: any,
  userAccountId?: any,
  startDate?: any,
  endDate?: any
) => {
  const params = new URLSearchParams();
  if (search) params.append("search", search);
  if (activePage) params.append("page", activePage.toString());
  if (userAccountId)
    params.append("account_subscription_type", userAccountId.toString());
  if (startDate)
    params.append("from_date", moment(startDate).format("YYYY-MM-DD"));
  if (endDate) params.append("to_date", moment(endDate).format("YYYY-MM-DD"));

  const url = `${
    Endpoint.product_manager.parentsAccounts
  }?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const blockUserService = (id: any) => {
  return HTTP_CLIENT.post(
    Endpoint.product_manager.blockUser.replace(":id", id)
  );
};

const unblockUserService = (id: any) => {
  return HTTP_CLIENT.post(
    Endpoint.product_manager.unblockUser.replace(":id", id)
  );
};

const statsParentsService = () => {
  return HTTP_CLIENT.get(Endpoint.product_manager.statsParents);
};

const statsIndividualKidsService = () => {
  return HTTP_CLIENT.get(Endpoint.product_manager.statsIndividual);
};

const getAllAuthors = (search: any) => {
  const params = new URLSearchParams();
  if (search) params.append("search", search);
  params.append("per_page", "20");

  const url = `${
    Endpoint.product_manager.getAllAuthorsList
  }?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const getAllPublishers = (search: any) => {
  const params = new URLSearchParams();
  if (search) params.append("search", search);
  params.append("per_page", "20");

  const url = `${
    Endpoint.product_manager.getAllPublishersList
  }?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const getAllBooks = (search: any) => {
  const params = new URLSearchParams();
  if (search) params.append("search", search);
  params.append("per_page", "20");

  const url = `${
    Endpoint.product_manager.getAllBooksList
  }?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const getAllCustomNotifications = (activePage: any) => {
  const params = new URLSearchParams();
  if (activePage) {
    params.append("page", activePage.toString());
  }

  const url = `${
    Endpoint.product_manager.getAllCustomNotifications
  }?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const getSingleCustomNotification = (id: any) => {
  const params = new URLSearchParams();

  const url = `${Endpoint.product_manager.getCustomNotification?.replace(
    ":id",
    id
  )}?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const cancelCustomNotification = (id: any) => {
  return HTTP_CLIENT.post(
    Endpoint.product_manager.cancelNotification.replace(":id", id)
  );
};

const createNotification = (resp: {}) => {
  return HTTP_CLIENT.post(Endpoint.product_manager.createNotification, resp);
};

const getPublisherProfileService = (id: number) => {
  return HTTP_CLIENT.get(Endpoint.product_manager.publisherProfile + id);
};

const getAuthorProfileService = (id: number) => {
  return HTTP_CLIENT.get(Endpoint.product_manager.authorProfile + id);
};

const createPhotoLinkService = (file: any) => {
  const formData = new FormData();
  formData.append("image", file);
  return HTTP_CLIENT.post(Endpoint.product_manager.createPhotoLink, formData);
};

const getAllBannerService = (currentPage: any) => {
  const params = new URLSearchParams();
  if (currentPage) params.append("page", currentPage.toString());

  return HTTP_CLIENT.get(`${Endpoint.product_manager.getAllBanners}?${params}`);
};

const addAnnouncementsBannersService = (resp: any) => {
  let formBody = new FormData();
  Object.keys(resp).forEach((key) => {
    formBody.append(key, resp[key]);
  });
  return HTTP_CLIENT.post(
    Endpoint.product_manager.addAnnouncementBanners,
    formBody
  );
};

const updateAnnouncementStatus = (resp: any) => {
  return HTTP_CLIENT.post(
    Endpoint.product_manager.updateAnnouncementStatus,
    resp
  );
};

const deleteAnnouncementService = (id: any) => {
  return HTTP_CLIENT.post(
    Endpoint.product_manager.deleteAnnouncementStatus.replace(
      ":id",
      id.toString()
    )
  );
};

const getSingleAnnouncement = (id: any) => {
  return HTTP_CLIENT.post(
    Endpoint.product_manager.getSingleAnnouncement.replace(":id", id.toString())
  );
};

const updateAnnouncementService = (id: any, resp: any) => {
  let formBody = new FormData();
  Object.keys(resp).forEach((key) => {
    formBody.append(key, resp[key]);
  });
  return HTTP_CLIENT.post(
    Endpoint.product_manager.updateAnnouncements.replace(":id", id.toString()),
    formBody
  );
};

export {
  getAllBannerService,
  getAllPlansService,
  getAllSchoolListService,
  createPlanService,
  updatePlanService,
  deletePlanService,
  activatePlanService,
  inActivatePlanService,
  getAllBalanceService,
  addBalanceService,
  updateBalanceService,
  deleteBalanceService,
  getFeaturedBooksService,
  approveFeatureBookService,
  rejectBookService,
  uploadFeatureCoverService,
  getAllPublishersService,
  getAllPublisherBooks,
  addBookFeatureService,
  activateFeatureBookService,
  inActivateFeatureBookService,
  getBookHistoryService,
  allReportedBooksLists,
  pendingBooksLists,
  historyBookLists,
  approvedBookService,
  declineBookService,
  allGenreService,
  createGenreService,
  updateGenresService,
  deleteGenresService,
  activateGenreService,
  inActivateGenreService,
  ticketUserService,
  createTicketByProductManager,
  addMessageByProductManager,
  closeTicketByProductManager,
  getUserTicketsManager,
  getThreadProductManager,
  getAllUsersProductManager,
  getCatalogStats,
  getGenreStats,
  getBooksStats,
  getSoftCopyStats,
  getHardCopyStats,
  getTopSalesHardCopy,
  getTopSalesSoftCopy,
  deleteBookService,
  getRevisionHistoryByProductManager,
  getAllGraphsData,
  getSalesBooks,
  getTransactionRequestList,
  rejectTrasactionByProductManager,
  approveTransactionByProductManager,
  getPolicyDataAPI,
  updatePolicyDataAPI,
  getProductManagerSubscriptionsList,
  getUserTransactionsList,
  getNewsletterListProductManager,
  deleteNewsLetterProductManager,
  createTicketAndServiceByProductManager,
  transactionHistoryList,
  checkTransactionInquiry,
  getBannerListings,
  addBanner,
  deleteBanner,
  individualAccountService,
  parentAccountsService,
  blockUserService,
  unblockUserService,
  statsParentsService,
  statsIndividualKidsService,
  getAllAuthors,
  getAllPublishers,
  getAllBooks,
  getAllCustomNotifications,
  getSingleCustomNotification,
  cancelCustomNotification,
  createNotification,
  getPublisherProfileService,
  getAuthorProfileService,
  createPhotoLinkService,
  addAnnouncementsBannersService,
  updateAnnouncementStatus,
  deleteAnnouncementService,
  updateAnnouncementService,
  getSingleAnnouncement,
};
