import DoneIcon from "@mui/icons-material/Done";
import { Grid } from "@mui/material";
import classNames from "classnames";
import DatePicker from "react-datepicker";
import styles from "../style.module.scss";

function DiscountSection({ isDiscounted, setIsDiscounted, formik }: any) {
  const { values, setFieldValue } = formik;

  return (
    <div>
      <div
        className={styles.singleSelection}
        onClick={() => {
          setIsDiscounted(!isDiscounted);
          setFieldValue("is_discounted", !values?.is_discounted);
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "6px",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <div
            className={classNames(
              styles.checkbox,
              isDiscounted && styles.checked
            )}
          >
            <DoneIcon
              sx={{
                color: "white",
                height: "12px",
                width: "12px",
              }}
            />
          </div>
        </div>
        <label
          className={
            isDiscounted
              ? classNames(styles.selectedLabel, styles.selectionLabel)
              : classNames(styles.selectionLabel)
          }
        >
          Add Discount
        </label>
      </div>

      {isDiscounted && (
        <div className={styles.discountContainer}>
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <label className={styles.inputLabel}>Discount Title</label>
                <input
                  className={styles.input}
                  placeholder="e.g. Eid special"
                  id="discountTitle"
                  name="discountTitle"
                  type="discountTitle"
                  value={formik?.values?.discountTitle}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik?.touched?.discountTitle &&
                  formik?.errors?.discountTitle && (
                    <div className={styles.errorText}>
                      {formik?.errors?.discountTitle}
                    </div>
                  )}
              </div>
            </Grid>
            <Grid xs={6} item>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <label className={styles.inputLabel}>Discount amount</label>
                <input
                  className={styles.input}
                  placeholder="e.g. 10"
                  id="discountAmount"
                  name="discountAmount"
                  type="discountAmount"
                  value={formik?.values?.discountAmount}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik?.touched?.discountAmount &&
                  formik?.errors?.discountAmount && (
                    <div className={styles.errorText}>
                      {formik?.errors?.discountAmount}
                    </div>
                  )}
              </div>
            </Grid>
            <Grid xs={6} item>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <label className={styles.inputLabel}>Start Date</label>
                <DatePicker
                  selected={formik.values.startDate}
                  onChange={(date: Date) => {
                    formik.setFieldValue("startDate", date);
                    formik.setFieldValue("endDate", null);
                  }}
                  format="DD-MM-YYYY"
                  minDate={new Date()}
                  className={styles.inputField}
                />
                {formik.touched.startDate && formik.errors.startDate && (
                  <div className={styles.errorText}>
                    {formik.errors.startDate}
                  </div>
                )}
              </div>
            </Grid>
            <Grid xs={6} item>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <label className={styles.inputLabel}>End Date</label>
                <DatePicker
                  selected={formik.values.endDate}
                  onChange={(date: Date) => {
                    formik.setFieldValue("endDate", date);
                  }}
                  minDate={new Date()}
                  className={styles.inputField}
                  format="DD-MM-YYYY"
                />
                {formik.touched.endDate && formik.errors.endDate && (
                  <div className={styles.errorText}>
                    {formik.errors.endDate}
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
}

export default DiscountSection;
