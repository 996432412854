import DoneIcon from "@mui/icons-material/Done";
import classNames from "classnames";
import { plan_level_types } from "../constant";
import styles from "../style.module.scss";

const LevelSelection = ({ formik, selectedItem }: any) => {
  const { values } = formik;

  return (
    <div className={styles.selectionContainer}>
      {plan_level_types?.map((item) => {
        return (
          <div className={styles.singleSelection}>
            <div
              style={{
                display: "flex",
                gap: "6px",
                alignItems: "center",
                justifyContent: "end",
              }}
              onClick={() => {
                if (!selectedItem) {
                  formik.setFieldValue("level", item?.value);
                  
                }
              }}
            >
              <div
                className={classNames(
                  styles.checkbox,
                  values?.level === item?.value && styles.checked
                )}
              >
                <DoneIcon
                  sx={{
                    color: "white",
                    height: "12px",
                    width: "12px",
                  }}
                />
              </div>
            </div>
            <label
              className={
                values?.level === item?.value
                  ? classNames(styles.selectedLabel, styles.selectionLabel)
                  : classNames(styles.selectionLabel)
              }
            >
              {item?.label}
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default LevelSelection;
